import { UICommandInterface } from './ui-command.interface';
import { CommandTypes } from './command-types';
import { MessageResourceManager } from '../../resources/message-resource-manager';
import { MessageFormatter } from '../../resources/message-formatter';
import { UICommandSettingsManagerInterface } from './ui-command-settings-manager.interface';

export class UICommandSettingsManager implements UICommandSettingsManagerInterface {

    setUICommandByString(stringifiedEnum: string, ui: UICommandInterface): UICommandInterface {
        const displayName = MessageResourceManager.Current.getMessage(MessageFormatter.formatNumber('std_CMD_{0}', stringifiedEnum));
        const tooltip = MessageResourceManager.Current.getMessageIfExists(MessageFormatter.formatNumber('std_CMD_{0}_Tooltip', stringifiedEnum));

        ui.displayName = displayName;
        ui.name = stringifiedEnum;
        ui.tooltip = tooltip;
        return ui;
    }

    setUICommand(en: any, ui: UICommandInterface): UICommandInterface {

        const stringifiedEnum = CommandTypes[en];

        this.setUICommandByString(stringifiedEnum, ui);

        switch (en) {
            case CommandTypes.Create:
                ui.iconClass = 'plus';
                break;
            case CommandTypes.Remove:
                ui.iconClass = 'trash';
                break;
            case CommandTypes.Restore:
                ui.iconClass = 'undo-action';
                break;
            case CommandTypes.Store:
                ui.iconClass = 'check';
                break;
            case CommandTypes.Validate:
                ui.iconClass = 'check';
                break;

            case CommandTypes.ToggleLeftSidebar:
                ui.iconClass = 'sidebar-expand';
                break;

            case CommandTypes.ToggleRightSidebar:
                ui.iconClass = 'sidebar-expand';
                break;

            case CommandTypes.ToggleExtendedFilter:
                ui.iconClass = 'ToggleExtendedFilter';
                break;

            case CommandTypes.FocusGrid:
                ui.iconClass = 'enlarge';
                break;

            case CommandTypes.ExitFocusGrid:
                ui.iconClass = 'reduce';
                break;

            case CommandTypes.CreateItem:
                ui.iconClass = 'plus';
                break;

            case CommandTypes.RemoveItem:
                ui.iconClass = 'minus';
                break;

            case CommandTypes.ViewItem:
                ui.iconClass = 'open-in-window';
                break;

            case CommandTypes.Copy:
                ui.iconClass = 'copy';
                break;

            case CommandTypes.ExportCSV:
                ui.iconClass = 'download';
                break;

            case CommandTypes.OpenGridSettings:
                ui.iconClass = 'settings';
                break;

            case CommandTypes.ToggleGridFilters:
                ui.iconClass = 'filter';
                break;

            case CommandTypes.ToggleSnapShot:
                ui.iconClass = 'ToggleSnapShot';
                break;

            case CommandTypes.Find:
                ui.iconClass = 'search';
                break;

            case CommandTypes.About:
                ui.iconClass = 'About';
                break;

            case CommandTypes.MoveItemUp:
                ui.iconClass = 'move-up';
                break;

            case CommandTypes.MoveItemDown:
                ui.iconClass = 'move-down';
                break;

            case CommandTypes.CustomizeFields:
                ui.iconClass = 'CustomizeFields';
                break;

            case CommandTypes.ResetLayout:
                ui.iconClass = 'ResetLayout';
                break;

            case CommandTypes.ChangeLayout:
                ui.iconClass = 'ChangeLayout';
                break;

            case CommandTypes.ExecuteLongOp:
                ui.iconClass = 'ExecuteLongOp';
                break;

            case CommandTypes.Schedule:
                ui.iconClass = 'Schedule';
                break;

            case CommandTypes.MoreOptions:
                ui.iconClass = 'horiz';
                break;

            case CommandTypes.MobileMenu:
                ui.iconClass = 'menu';
                break;

            case CommandTypes.ResetColumns:
                ui.iconClass = 'restart';
                break;

            case CommandTypes.StarItem:
                ui.iconClass = 'star';
                break;

            case CommandTypes.UnstarItem:
                ui.iconClass = 'star-filled';
                break;

            case CommandTypes.PinToDashboard:
                ui.iconClass = 'grid-add';

                // #8642 di default nascondere etichetta pulsante pin to dashboard
                ui.showDisplayName$.next(false);
                break;

            case CommandTypes.EditItem:
                ui.iconClass = 'edit-pencil';
                break;

            case CommandTypes.SendEmail:
                ui.iconClass = 'send-mail';
                break;

            case CommandTypes.CallPhone:
                ui.iconClass = 'phone';
                break;

            case CommandTypes.HistoryBack:
                ui.iconClass = 'arrow-back';
                break;
        }
        return ui;
    }
}

import { BehaviorSubject } from "rxjs";
import { PanelViewModelInterface } from "./panel-view-model.interface";
import { PropertyViewModelPropertyChangedEventArgs } from "./property-view-property-changed-event-args";
import { UUIDHelper } from "@nts/std/utility";
import { EventEmitter } from "@angular/core";

export class PanelViewModelUtility {
  static getPanelViewModel(panels: Map<string, PanelViewModelInterface>, panelId: string): PanelViewModelInterface {
    let panelVm = panels?.get(panelId);
    if (panelVm == null) {
      panelVm = new PanelViewModel();
      panels.set(panelId, panelVm);
    }
    return panelVm;
  }
}

export class PanelViewModel implements PanelViewModelInterface {

  propertyChanged: EventEmitter<PropertyViewModelPropertyChangedEventArgs> = new EventEmitter();
  readonly uniqueId = UUIDHelper.generateUUID();

  protected internalDisableAdditionalFields = false;

  get disableAdditionalFields() {
      return this.internalDisableAdditionalFields;
  }
  set disableAdditionalFields(value: boolean) {
      if (this.internalDisableAdditionalFields !== value) {
          this.internalDisableAdditionalFields = value;
          this.onPropertyChanged('disableAdditionalFields');
          this.disableAdditionalFields$.next(value);
      }
  }

  disableAdditionalFields$ = new BehaviorSubject<boolean>(this.internalDisableAdditionalFields);

  protected internalIsVisible = true;

  get isVisible() {
      return this.internalIsVisible;
  }
  set isVisible(value: boolean) {
      if (this.internalIsVisible !== value) {
          this.internalIsVisible = value;
          this.onPropertyChanged('isVisible');
          this.isVisible$.next(value);
      }
  }

  isVisible$ = new BehaviorSubject<boolean>(this.internalIsVisible);

  protected internalIsCollapsed = false;

  get isCollapsed() {
      return this.internalIsCollapsed;
  }
  set isCollapsed(value: boolean) {
      if (this.internalIsCollapsed !== value) {
          this.internalIsCollapsed = value;
          this.onPropertyChanged('isCollapsed');
          this.isCollapsed$.next(value);
      }
  }

  isCollapsed$ = new BehaviorSubject<boolean>(this.internalIsCollapsed);

  onPropertyChanged(propertyName: string = null) {
    const args = new PropertyViewModelPropertyChangedEventArgs();
    args.propertyName = propertyName;
    this.propertyChanged.emit(args);
  }
}

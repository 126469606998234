import { Expose, Type } from '@nts/std/serialization';
import { ExternalFieldInputMode } from './external-field-meta-data';
import { FieldMetaData } from './field-meta-data';
import { FieldTypes } from './field-types.enum';
import { SimpleFieldMetaData } from './simple-field-meta-data';

export class ExternalLayoutMetaData extends FieldMetaData {

  @Expose()
  @Type(() => SimpleFieldMetaData)
  availableExternalFields: SimpleFieldMetaData[];

  @Expose()
  searchProperties?: string[];

  @Expose()
  decodeProperties?: string[];

  @Expose()
  codeProperties?: SimpleFieldMetaData[];

  @Expose()
  fieldSeparator?: string;

  @Expose()
  inputMode: ExternalFieldInputMode;

  get isAutocomplete() {
    return this.inputMode == ExternalFieldInputMode.Autocomplete;
  }

  get isExternalList() {
    return this.inputMode == ExternalFieldInputMode.ExternalList;
  }

  getFieldType() {
    if (this.isExternalList) {
      return FieldTypes.ExternalList;
    }
    return FieldTypes.External;
  }
}

import { BaseError } from '../../messages/base-error';
import { Information } from '../../messages/information';
import { MessageButton } from './message-button';
import { MessageResult } from './message-result';
import { ModalViewModelInterface } from './modal-view-model.interface';
import { ModalResult } from './modal-result';
import { Injectable } from '@angular/core';
import { ZoomUIStarterArgs } from '../zoom/zoom-ui-starter-args';
import { ZoomResult } from '../../domain-models/zoom/zoom-result';
import { MetaDataDescriptions } from '../../meta-data/meta-data-descriptions';
import { MatDialogConfig } from '@angular/material/dialog';
import { CoreOrchestratorViewModelInterface } from '../core-orchestrator-view-model.interface';
import { ActivatedRoute } from '@angular/router';
import { ExternalModalViewModelInterface } from './external-modal-view-model.interface';

export interface ShowMessageInterface {
  title: string,
  message?: string,
  buttons?: MessageButton,
  metaDataDescriptionsButtons?: MetaDataDescriptions[],
  panelClass?: string,
  width?: string|undefined
}

export interface ShowStandardModalInterface {
  buttons: MessageButton,
  title?: string,
  viewModel?: ModalViewModelInterface<any, void>,
  config?: MatDialogConfig<any>,
  metaDataDescriptionsButtons?: MetaDataDescriptions[],
  isCentered?: boolean
}
export interface ShowPopUpModalInterface {
  informations: Array<Information>,
  errors?: Array<BaseError>,
  caption?: string,
  metaDataDescriptionsButton?: MetaDataDescriptions|undefined,
  panelClass?: string,
  width?: string|undefined;
}

export interface ShowCustomModalWithResulInterface<TResult> {
  viewModel: ModalViewModelInterface<any, TResult>,
  showCancel?: boolean,
  maximizable?: boolean,
  maximized?: boolean,
  config?: MatDialogConfig<any>,
  isCentered?: boolean
}

export interface ShowExternalModalInterface<TResult> {
  baseUrl: string,
  jsonIdentity?: string|undefined,
  additionalQueryParams?: URLSearchParams,
  modalTitle?: string,
  externalReturn?: boolean,
  supportRemoteClosingCheck?: boolean,
  customExternalModalViewModel?: ExternalModalViewModelInterface<any, TResult>|undefined,
  supportRemoteStatus?: boolean,
  panelClass?: string|undefined,
  width?: string|undefined
}

export interface ShowExceptionPopUpInterface {
  error: Error,
  caption?: string,
  message?: string,
  metaDataDescriptionsButton?: MetaDataDescriptions|undefined,
  panelClass?: string|undefined,
  width?: string|undefined
}

@Injectable()
export abstract class ModalService {

    ovm: CoreOrchestratorViewModelInterface;
    route?: ActivatedRoute;

    abstract showStandardModalAsync(
        buttons: MessageButton | ShowStandardModalInterface,
        title?: string,
        viewModel?: ModalViewModelInterface<any, void>,
        config?: MatDialogConfig<any>,
        metaDataDescriptionsButtons?: MetaDataDescriptions[],
        isCentered?: boolean
    ): Promise<MessageResult>;

    abstract showCustomModalWithResultAsync<TResult>(
        viewModel: ModalViewModelInterface<any, TResult>| ShowCustomModalWithResulInterface<TResult>,
        showCancel?: boolean,
        maximizable?: boolean,
        maximized?: boolean,
        config?: MatDialogConfig<any>,
        isCentered?: boolean
    ): Promise<ModalResult<TResult>>;

    abstract showExternalModal<TResult>(
        baseUrl: string | ShowExternalModalInterface<TResult>,
        jsonIdentity?: string|undefined,
        additionalQueryParams?: URLSearchParams,
        modalTitle?: string,
        externalReturn?: boolean,
        supportRemoteClosingCheck?: boolean,
        customExternalModalViewModel?: ExternalModalViewModelInterface<any, TResult>|undefined,
        supportRemoteStatus?: boolean,
        panelClass?: string|undefined,
        width?: string|undefined
    ): Promise<ModalResult<TResult>>

    abstract showZoomAsync(
      viewModel: ModalViewModelInterface<ZoomUIStarterArgs, ZoomResult>,
      maximizable?: boolean,
      panelClass?: string|undefined,
      width?: string|undefined
    ): Promise<ModalResult<ZoomResult>>;

    abstract showMessageAsync(
      title: string | ShowMessageInterface,
      message?: string,
      buttons?: MessageButton,
      metaDataDescriptionsButtons?: MetaDataDescriptions[],
      panelClass?: string|undefined,
      width?: string|undefined
    ): Promise<MessageResult>;

    abstract showExceptionPopUp(
      error: Error|ShowExceptionPopUpInterface,
      caption?: string,
      message?: string,
      metaDataDescriptionsButton?: MetaDataDescriptions|undefined,
      panelClass?: string|undefined,
      width?: string|undefined
    );

    abstract showPopUp(
      informations: Array<Information> | ShowPopUpModalInterface,
      errors?: Array<BaseError>,
      caption?: string,
      metaDataDescriptionsButton?: MetaDataDescriptions|undefined,
      panelClass?: string|undefined,
      width?: string|undefined
    ): Promise<MessageResult>;

}

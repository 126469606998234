import { AgRendererComponent } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { BaseNumericPropertyViewModel } from '../../../../../src/lib/view-models/base-type/base-numeric-property-view-model';
import { BaseBoolPropertyViewModel } from '../../../../../src/lib/view-models/base-type/bool-property-view-model';
import { BaseDateTimeOffsetPropertyViewModel } from '../../../../../src/lib/view-models/base-type/date-time-offset-property-view-model';
import { BaseDateTimePropertyViewModel } from '../../../../../src/lib/view-models/base-type/date-time-property-view-model';
import { BaseEnumPropertyViewModel } from '../../../../../src/lib/view-models/base-type/enum-property-view-model';
import { StringPropertyViewModel } from '../../../../../src/lib/view-models/base-type/string-property-view-model';
import { PropertyViewModelInterface } from '../../../../../src/lib/view-models/property-view-model.interface';
import { BoolCellRendererComponent } from '../bool-cell-renderer/bool-cell-renderer.component';
import { ColumnCellRendererParams } from '../column_cell_renderer_params.interface';
import { DateCellRendererComponent } from '../date_cell_renderer.component';
import { DefaultCellRendererComponent } from '../default_cell_renderer.component';
import { EnumCellRendererComponent } from '../enum_cell_renderer.component';
import { ExternalCellRendererComponent } from '../external-cell-renderer/external-cell-renderer.component';
import { NumericCellRendererComponent } from '../numeric_cell_renderer.component';
// create your cellEditor as a Angular component@UntilDestroy()
@UntilDestroy()
@Component({
    selector: 'nts-dynamic-text-box-renderer-cell',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        AsyncPipe,
        DefaultCellRendererComponent,
        BoolCellRendererComponent,
        DateCellRendererComponent,
        EnumCellRendererComponent,
        NumericCellRendererComponent,
        ExternalCellRendererComponent
    ],
    template: 
        `<nts-default-text-box-renderer-cell *ngIf="!params || params.data == null" [params]="params"></nts-default-text-box-renderer-cell>
         <nts-bool-text-box-renderer-cell *ngIf="isBool$ | async" [params]="params"></nts-bool-text-box-renderer-cell>
         <nts-date-text-box-renderer-cell *ngIf="isDate$ | async" [params]="params"></nts-date-text-box-renderer-cell>
         <nts-default-text-box-renderer-cell *ngIf="isString$ | async" [params]="params"></nts-default-text-box-renderer-cell>
         <nts-enum-text-box-renderer-cell *ngIf="isEnum$ | async" [params]="params"></nts-enum-text-box-renderer-cell>
         <nts-external-renderer-cell *ngIf="isExternal$ | async" [params]="params"></nts-external-renderer-cell>
         <nts-numeric-text-box-renderer-cell *ngIf="isNumeric$ | async" [params]="params"></nts-numeric-text-box-renderer-cell>`,
    styleUrls: [`./dynamic-cell-renderer.component.scss`]
})
export class DynamicCellRendererComponent implements AgRendererComponent {

    isBool$ = new BehaviorSubject<boolean>(false);
    isDate$ = new BehaviorSubject<boolean>(false);
    isString$ = new BehaviorSubject<boolean>(false);
    isEnum$ = new BehaviorSubject<boolean>(false);
    isNumeric$ = new BehaviorSubject<boolean>(false);
    isExternal$ = new BehaviorSubject<boolean>(false);

    params: ICellRendererParams;

    agInit(params: ICellRendererParams): void {
        this.params = params;
        this.updateComponentType();
    }

    updateComponentType() {
        if(this.params.data != null) {
            const externalPropertyTypeNames = [
                'ExternalCode-Numeric',
                'ExternalCode-String',
                'ExternalCodeRef',
                'ExternalDecode-Bool',
                'ExternalDecode-DateTime',
                'ExternalDecode-Enum',
                'ExternalDecode-Numeric',
                'ExternalDecode-String'
            ]

            if (this.propertyViewModel instanceof BaseBoolPropertyViewModel) {
                this.isBool$.next(true);
            } else if (this.propertyViewModel instanceof BaseDateTimePropertyViewModel) {
                this.isDate$.next(true);
            } else if (this.propertyViewModel instanceof BaseDateTimeOffsetPropertyViewModel) {
                this.isDate$.next(true);
            } else if (this.propertyViewModel instanceof StringPropertyViewModel) {
                this.isString$.next(true);        
            } else if (this.propertyViewModel instanceof BaseEnumPropertyViewModel) {
                this.isEnum$.next(true);
            } else if (this.propertyViewModel instanceof BaseNumericPropertyViewModel) {
                this.isNumeric$.next(true);
            } else if (externalPropertyTypeNames.indexOf((this.params as ColumnCellRendererParams).columnInfo.propertyTypeName) > -1) {
                this.isExternal$.next(true);
            }
        }
    }

    refresh(params: ICellRendererParams) {
        this.params = params;
        this.updateComponentType();
        return true;
    }

    private _propertyViewModel: PropertyViewModelInterface;
    get propertyViewModel(): PropertyViewModelInterface {
        if (!this._propertyViewModel) {
            this._propertyViewModel =  (this.params as ColumnCellRendererParams).columnInfo.fieldName.split('.').reduce(
                (o, i) => {
                    if (i === 'value') {
                        return o;
                    }
                    if(o != null){
                        return o[i];
                    }
                    return null;
                }, this.params.data) as PropertyViewModelInterface;
        }
        return this._propertyViewModel;
    }
}

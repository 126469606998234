import { Expose, Type } from '@nts/std/serialization';
import { GridColumnsMetaData } from './grid-columns-meta-data';
import { FieldMetaData } from './field-meta-data';
import { FieldTypes } from './field-types.enum';

export class GridMetaData extends FieldMetaData {

    constructor() {
        super();
        this.gridColumns = new GridColumnsMetaData();
    }

    @Expose()
    @Type(() => GridColumnsMetaData)
    gridColumns: GridColumnsMetaData;

    @Expose()
    areAllColumnsDisabled: boolean;

    @Expose()
    areAllCommandsDisabled: boolean;

    @Expose()
    showTitle: boolean;

    @Expose()
    showToolbar: boolean;

    @Expose()
    dynamicHeight: boolean;

    @Expose()
    minRowNumberHeight: number;

    override getFieldType(): FieldTypes {
      return FieldTypes.Grid;
    }
}

import { Expose } from '@nts/std/serialization';
import { ExternalLayoutMetaData } from './external-layout-meta-data';
import { GridFieldInterface } from './grid-field.interface';

export class ExternalGridFieldMetaData extends ExternalLayoutMetaData implements GridFieldInterface {

  constructor() {
    super();
  }

  @Expose()
  isAutoSize: boolean;

  @Expose()
  width: number;
}

import { Expose } from '@nts/std/serialization';
import { FieldMetaData } from './field-meta-data';
import { FieldTypes } from './field-types.enum';

export class SpacerMetaData extends FieldMetaData {

  override getFieldType(): FieldTypes {
    return FieldTypes.Spacer;
  }
}

import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { BaseTextBoxComponent } from "../core/base/base-text-box/base-text-box.component";
import { AsyncPipe, NgIf } from "@angular/common";
import { BaseTextAreaBoxComponent } from "../core/base/base-text-area-box/base-text-area-box.component";
import { NgxPopperjsModule } from "ngx-popperjs";

@UntilDestroy()
@Component({
    selector: 'nts-ribbon-text-box',
    templateUrl: './ribbon-text-box.component.html',
    styleUrls: ['./ribbon-text-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        BaseTextBoxComponent,
        NgIf,
        BaseTextAreaBoxComponent,
        NgxPopperjsModule,
        AsyncPipe
    ]
})
export class RibbonTextBoxComponent implements AfterViewInit {

  @ViewChild('baseTextBox', { static: false }) baseTextBox: BaseTextBoxComponent;

  @Input() placeholder: string = '';

  @Output() onInputChange = new EventEmitter<string>();

  element: ElementRef;

  get inputValue() {
    if (this.element) {
        return this.element.nativeElement.value;
    } else {
        return null;
    }
  }

  ngAfterViewInit(): void {
    if (this.baseTextBox) {
        this.element = this.baseTextBox.textBox;
    }
  }

  onInput(e) {
    this.onInputChange.emit(this.inputValue);
  }

}

<div [class.hide-additional-fields]="!showAdditionalFields"
    class="main-container" [class.collapsed]="computedIsCollapsed$ | async"
    [attr.data-is-collapsed]="computedIsCollapsed$ | async"
    [class.is-hidden]="!(computedIsVisible$ | async)"
    [class.expanded]="!(computedIsCollapsed$ | async)" [attr.data-label]="title" [class.has-border]="showBorder">

    <!-- Header -->
    <div class="header-container header-container-collapsible" [class.hide-header]="!showHeader">
        <span (click)="setCollapsed(!computedIsCollapsed$.value)"
            class="header-toggle">
            <span class="caption-text" role="heading">{{title}}</span></span>
        <span *ngIf="promotedFields.length" class="importance-promoted-container">
            <div *ngFor="let pvm of promotedFieldsFiltered"
                popper="{{pvm.metadataDescription}}"
                [popperTrigger]="ngxPopperjsTriggers.hover"
                [popperDelay]="1000"
                popperApplyClass="info"
                [popperPlacement]="ngxPopperjsPlacements.TOP"
                [popperPreventOverflow]="false"
                [popperHideOnScroll]="true"
                popperAppendTo="body"
                [attr.data-referencecontrolid]="pvm.uniqueId"
                class="importance-promoted">
                {{ pvm.formattedValue}}
            </div>
        </span>
        <div class="header-spacing"></div>

        <div class="header-commands">
            <ng-template ngFor let-cmd [ngForOf]="defaultCommands.concat(commands)">
                <nts-text-button
                    class="action-toggle"
                    *ngIf="cmd.isVisible$ | async"
                    [isDisabled]="!(cmd.canExecute$ | async)"
                    [title]="cmd.tooltip$ | async"
                    [label]="cmd.displayName$ | async"
                    [isLoading]="cmd.loading$ | async"
                    [icon]="cmd.iconClass$ | async"
                    (onClicked)="cmd.execute()">
                </nts-text-button>
            </ng-template>
        </div>

    </div>

    <!-- Content -->
    <div class="collapsible-tab" [class.hide-header]="!showHeader">
        <div class="multiple-columns-group">
            <ng-content></ng-content>
        </div>
    </div>
</div>

import { AggregateElementViewModel } from './aggregate-element-view-model';
import { CoreOrchestratorViewModelInterface } from './core-orchestrator-view-model.interface';
import { AccessMode, AggregateMetaData, InternalCollectionMetaData, MetaDataUtils } from '../meta-data';
import { ColumnsProviderInterface } from './columns_provider.interface';
import { ColumnsUtils } from './columns_utils';
import { ColumnInfoCollection } from './column-info-collection';
import { CoreModel } from '../domain-models/core-model';
import { BaseIdentity } from '../domain-models';
import { RootViewModelInterface } from './root-view-model.interface';
import { ClassInformationInterface, ClassInformationType, LogService } from '@nts/std/utility';
import { CollectionViewModelInterface } from './collection-view-model.interface';
import { ExternalFieldInputMode } from '../layout-meta-data/external-field-meta-data';
import { ReplaySubject } from 'rxjs';
import { ClassConstructor } from '@nts/std/serialization';
import { GetGridColumnsParamsInterface } from './zoom/get-grid-columns-params.interface';
import { PanelViewModel } from './panel.view-model';

export class RootViewModel<TModel extends CoreModel<TIdentity>, TIdentity extends BaseIdentity>
    extends AggregateElementViewModel<TModel, TIdentity> implements RootViewModelInterface, ClassInformationInterface {

    classType = ClassInformationType.RootViewModel;
    currentStateChanged: ReplaySubject<void> = new ReplaySubject();
    panels: Map<string, PanelViewModel> = new Map();

    private _columnsProvider: ColumnsProviderInterface;

    /**
     * @param entityTypeName
     * @param baseTypesAvailableColumns indica le colonne per disponibili per i tipi base se "null" indica che non ci sono props per i
     *                                  basetype (es. solo external) se [] indica che tutte le prop della entity sono anche presenti nel vm
     * @param externalColumnsMapInfo    indica in maniera esplicita le colonne per le props di tipo External
     * @param groupByColumns            colonne (in ordine) per le quali raggruppare/ordinare
     */



    /**
     *
     * @param fullPathName                  percorso relativo alla root del modello della collection. Dalla versione REL_8.0.x escluso utilizzare "Named Parameters" seguendo l'interfaccia GetGridColumnsParams
     *
     * @deprecated Since REL_8.0.x Devi utilizzare la modalita' "Named Parameters"
     *
     * @param entityTypeName                modello della collection.
     *
     * @param visibilityDefinition          accetta la lista delle property da rendere visibili.
     *                                      Le property di collegamento con il parent saranno sempre invisibili.
     *                                      Le property serial saranno sempre invisibili.
     *                                      Default tutto invisibile.
     *
     * @param editableDefinition            lista delle property da disabilitare, in alternativa se viene passato false, tutto viene disabilitato.
     *                                      Le property di collegamento con il parent saranno sempre disabilitate.
     *                                      Le property serial saranno sempre disabilitate.
     *                                      Le property row number saranno sempre disabilitate.
     *                                      Le ulteriori property identity possono essere abilitate solo se la riga è nuova.
     *                                      Le decode degli external saranno sempre disabilitate.
     *                                      Default tutto abilitato tranne le eccezzioni definite precedentemente.
     *
     * @param orderDefinition               imposta lo stesso ordinamento delle property passate.
     *                                      Le property non elencate avranno tutte la stessa position superiore all'ultima dichiarata.
     *                                      Tutte le property che saranno sempre invisibili avrannno position 0.
     *                                      Default stesso ordinamento delle visibilityDefinition altrimenti ordine casuale in base alla lettura dei meta dati.
     *
     * @param widthDefinition               definisce le dimensioni delle colonne
     *
     * @param autocompleteDefinition        definisce la definizion custom per gli autocomplete
     *
     * @returns                             la lista delle colonne costruite in base ai paramtri passati.
     */
    getGridColumns(
        fullPathName: string | GetGridColumnsParamsInterface,
        entityTypeName: string = "",
        visibilityDefinition: string[] = null,
        editableDefinition: string[] | boolean = null,
        orderDefinition: string[] = null,
        widthDefinition: { fullPropertyName: string, isAutoSize: boolean, width: number }[] = null,
        autocompleteDefinition: {
            fullPropertyName: string,           // Percorso completo della code property con incluso il suo nome separata da '.'
            inputMode?: ExternalFieldInputMode, // Default ExternalFieldInputMode.Autocomplete

            /**
             * @deprecated utilizzare il campo decodeProperties e aggiungere i codici che si vogliono visualizzare
             */
            showCodeInDescription?: boolean,

            decodeProperties?: string[], // Properties che vogliamo visualizzare
            searchProperties?: string[], // Properties che vogliamo utilizzare per la ricerca
            fieldSeparator?: string // simbolo di separazione tra piu properties
        }[] = null
    ): ColumnInfoCollection {

        if (typeof fullPathName != "string") {
            // Utilizzo "var" al posto di "let" o "const" perche' la visibilita' sarebbe stata limitata al "if"
            var {
                entityTypeName,
                visibilityDefinition,
                editableDefinition,
                orderDefinition,
                widthDefinition,
                autocompleteDefinition
            } = fullPathName;
            var fullPathName: string | GetGridColumnsParamsInterface = fullPathName.fullPathName;
        }

        let gridColumns: ColumnInfoCollection;

        let gridUserLayout = this._columnsProvider.userLayoutMetaData?.grids?.length > 0 ?
            this._columnsProvider.userLayoutMetaData.grids.find((g) => {
                const fullPathNameCamelCase = g.fullPathName
                    .split('.')
                    .map((f) => MetaDataUtils.toCamelCase(f))
                    .join('.')

                return fullPathNameCamelCase === fullPathName;
            })
            : null

        // rimuovo le colonne invisibili
        if (gridUserLayout?.customizedColumns?.length > 0) {
            gridUserLayout.customizedColumns = gridUserLayout.customizedColumns.filter((c) => c.isUserVisible);
        }

        if (!gridUserLayout && this._columnsProvider.userLayoutMetaData?.grids?.length > 0) {
            LogService.log(
                `ATTENZIONE potresti non aver definito correttamente il fullPathName nella definizione di colonna di ${entityTypeName}, path corrente: ${fullPathName}`,
                this._columnsProvider.userLayoutMetaData?.grids
            )
        }

        // La grid user layout sarà presente solo nel layout standard
        if (gridUserLayout?.customizedColumns?.length > 0) {
            const gridUserLayoutFullPathNameCamelCase = gridUserLayout.fullPathName
                .split('.')
                .map((f) => MetaDataUtils.toCamelCase(f))
                .join('.')

            visibilityDefinition = gridUserLayout?.customizedColumns.map((c) => {
                let temp = c.fullPathName
                    .split('.')
                    .map((f) => MetaDataUtils.toCamelCase(f))
                    .join('.');

                if (temp.startsWith(gridUserLayoutFullPathNameCamelCase)) {
                    temp = temp.substring(gridUserLayoutFullPathNameCamelCase.length + 1);
                }

                return temp.replace('selectedItem.', '');
            });

            widthDefinition = widthDefinition ?? gridUserLayout?.customizedColumns.map((c) => {
                let temp = c.fullPathName
                    .split('.')
                    .map((f) => MetaDataUtils.toCamelCase(f))
                    .join('.');

                if (temp.startsWith(gridUserLayoutFullPathNameCamelCase)) {
                    temp = temp.substring(gridUserLayoutFullPathNameCamelCase.length + 1);
                }

                const fullPropertyName = temp.replace('selectedItem.', '');
                const isAutoSize = c.isAutoSize;
                const width = c.width;
                return {
                    fullPropertyName, isAutoSize, width
                }
            });

            visibilityDefinition = this.filterPropertyListBySecurity(fullPathName, visibilityDefinition);

            gridColumns = ColumnsUtils.getGridColumns(
                this._columnsProvider,
                entityTypeName,
                visibilityDefinition,
                editableDefinition,
                visibilityDefinition,
                widthDefinition,
                autocompleteDefinition
            );

            // Gestisce la visibility
            // if (gridUserLayout.customizedColumns?.length > 0) {
            // for (const column of gridColumns) {
            //     const found = gridUserLayout.customizedColumns.find((c) => {

            //         // path: modules.selectedItem.moduleRef
            //         // name: code

            //         // modules.selectedItem.moduleRef.code
            //         const fullPathWithSelectedItem = c.path + '.' + c.name;
            //         const searchString = '.selectedItem.';
            //         const first = fullPathWithSelectedItem.lastIndexOf(searchString) + searchString.length;

            //         // moduleRef.code
            //         const fullPath = fullPathWithSelectedItem.substring(first)

            //         return fullPath === column.fieldName.replace('.value', '')
            //     })
            //     column.isVisible = found?.isUserVisible === true;
            // }
            // // }

            // // TODO da verificare
            // orderDefinition = gridUserLayout.customizedColumns.map((c) => {
            //     const fullPathWithSelectedItem = c.path + '.' + c.name;
            //     const searchString = '.selectedItem.';
            //     const first = fullPathWithSelectedItem.lastIndexOf(searchString) + searchString.length;
            //     return fullPathWithSelectedItem.substring(first);
            // });

            // // Filtro le colonne che non sono definite nella definizione iniziale del layout standard
            // orderDefinition = orderDefinition.filter((columnName) => {
            //     return gridColumns.find((gridColumn) =>
            //         (
            //             gridColumn.isExternal ?
            //                 (gridColumn as ExtColumnInfo).path + '.' + gridColumn.propertyName :
            //                 gridColumn.propertyName
            //         ) === columnName
            //     ) != null;
            // })

            // ColumnsUtils.sortColumnByArray(gridColumns, orderDefinition);
        } else {

            visibilityDefinition = this.filterPropertyListBySecurity(fullPathName, visibilityDefinition);

            // Colonne definite nel template
            gridColumns = ColumnsUtils.getGridColumns(
                this._columnsProvider,
                entityTypeName,
                visibilityDefinition,
                editableDefinition,
                orderDefinition,
                widthDefinition,
                autocompleteDefinition
            );
        }

        return gridColumns;
    }

    private filterPropertyListBySecurity(collectionFullPathName: string, propertyList: string[]) {
        const foundCollectionMetaData = MetaDataUtils.getAssociationMetaDataDataFromPath(this.aggregateMetaData.rootMetaData, collectionFullPathName) as InternalCollectionMetaData;

        // Se non trovo la collection meta data non filtro
        if (!foundCollectionMetaData) {
            return propertyList;
        }

        return propertyList.filter((v) => {
            const pm = MetaDataUtils.getPropertyMetaDataFromPath(foundCollectionMetaData.dependentMetaData, v);
            if (pm && pm.userMetaData?.securityAccess === AccessMode.Deny) {
                return false;
            }

            // const splittedPropertyFullPath = v.split('.');
            // if (splittedPropertyFullPath.length === 1) {
            //     const pm = foundCollectionMetaData.dependentMetaData.getPropertyMetaData(v);
            //     if (pm && pm.userMetaData?.securityAccess === AccessMode.Deny) {
            //         return false;
            //     }
            // } else {

            // }
            return true;
        })
    }

    async initRootViewModel(
        domainModel: TModel,
        aggregateMetadata: AggregateMetaData,
        orchestratorViewModel: CoreOrchestratorViewModelInterface,
        ownerCollection?: CollectionViewModelInterface<any>,
        isNewItem?: boolean,
        path?: string,
        isMockedViewModel = false,
        rootDomainModelType: ClassConstructor<TModel> = null
    ) {

        this._columnsProvider = orchestratorViewModel;
        this.reservedPath = '';
        this.reservedName = '';
        this.isMock = isMockedViewModel;

        await this.initAggregateElementViewModel(
            domainModel,
            aggregateMetadata,
            orchestratorViewModel,
            undefined,
            this.isMock,
            rootDomainModelType
        );
    }

    /**
     * Viene utilizzata per inizializzare le custom grid column
     */
    async initCustomGridColumns(): Promise<void> {

    }

    setCustomColumnInfo(modelName: string, columnInfoCollection: ColumnInfoCollection) {
        this._columnsProvider.customGridColumns.set(modelName, columnInfoCollection);
    }

}

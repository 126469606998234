<textarea #textArea 
    (blur)="blur($event)"
    (input)="onInputChange($event)"
    (ngModelChange)="valueChange($event)"
    (focus)="focus($event)"
    (mouseenter)="mouseEnter($event)"  
    (mouseleave)="mouseLeave($event)"
    [ngModel]="value" 
    #popperError="popper"
    [popper]="tooltipErrTemplate"
    [popperDisabled]="!showErrorTooltip || !errorList || errorList?.length === 0"
    [popperTrigger]="ngxPopperjsTriggers.hover"
    popperApplyClass="error"
    [popperPlacement]="ngxPopperjsPlacements.TOP"
    [popperPreventOverflow]="false"
    [popperHideOnScroll]="true"
    popperAppendTo="body"
    [attr.maxlength]="maxLength"
    [attr.tabindex]="tabIndex" 
    [disabled]="isDisabled ? true: null" 
    [value]="value ? value : ''"
    [readonly]="isReadonly ? true : null" 
    [attr.placeholder]="placeholder" 
    [ngClass]="customClasses"
    [class.is-disabled]="isDisabled" 
    [class.is-readonly]="isReadonly" 
    [class.has-error]="showErrorBorder && errorList?.length > 0"
    [style.borderColor]="showErrorBorder && errorList?.length > 0 ? null : (overrideBorderColor ? overrideBorderColor : null)"
    [attr.rows]="rows"
    class="" spellcheck="false" autocomplete="autocomplete-off"></textarea>

<popper-content #tooltipErrTemplate>
    <ng-container *ngFor="let item of errorList">
        {{ item }}
    </ng-container>
</popper-content>
import { CheckBoxState } from '../../layout-meta-data/check-box-state.enum';
import { AccessMode } from '../../meta-data/access-mode.enum';
import { PropertyViewModel } from '../property-view-model';
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';

export abstract class BaseBoolPropertyViewModel extends PropertyViewModel<boolean>  {

    showLabel = false;

    constructor(initInfo: PropertyViewModelInitializationInfo, valueIsNullable: boolean) {
        super(initInfo, valueIsNullable);
    }

    override async resetValue(useDefaultValue: boolean): Promise<void> {
        await this.setValueAsync(useDefaultValue ? false : this._defaultFaultBackValue);
    }

    setDefaultValueFromLayoutMetaData(value: string|number|boolean) {
      if (value === true) {
          this._layoutDefaultValue = true;
      } else if (value === false) {
          this._layoutDefaultValue = false;
      }
  }
}

export class BoolPropertyViewModel extends BaseBoolPropertyViewModel {

    constructor(initInfo: PropertyViewModelInitializationInfo) {
        super(initInfo, false);
        this._defaultFaultBackValue = false;
    }

    getFormattedValue(updatedValue: boolean): string {

        if (this.securityAccess === AccessMode.Deny) {
            // TODO traduzioni
            return 'Non accessibile';
        }

        // TODO traduzioni
        return updatedValue ? 'Vero' : 'Falso';
    }
}

export class NBoolPropertyViewModel extends BaseBoolPropertyViewModel {

    constructor(initInfo: PropertyViewModelInitializationInfo) {
        super(initInfo, true);
        this._defaultFaultBackValue = null;
    }

    getFormattedValue(updatedValue: boolean): string {
        if (this.securityAccess === AccessMode.Deny) {
            // TODO traduzioni
            return 'Non accessibile';
        }

        // TODO traduzioni
        return updatedValue == null ?
            'Non impostato' :
            (updatedValue ? 'Vero' : 'Falso');
    }
}

<!-- Se necessiti degli expandable non è necessario usare il div modal-content-with-padding -->
<!-- <nts-expandable [disableToggle]="true"> -->
    <!-- <nts-labelled-text-box [propertyViewModel]="viewModel.title" [fullColumn]="true">
    </nts-labelled-text-box>

    <nts-labelled-text-box [propertyViewModel]="viewModel.isPreferred" [fullColumn]="true">
    </nts-labelled-text-box> -->
<!-- </nts-expandable> -->
<div class="modal-content-with-padding">
    <nts-labelled-text-box [propertyViewModel]="viewModel.title" [fullColumn]="true">
    </nts-labelled-text-box>

    <nts-labelled-text-box [propertyViewModel]="viewModel.isPreferred" [fullColumn]="true">
    </nts-labelled-text-box>
</div>

import { ExternalFieldInputMode } from '../layout-meta-data/external-field-meta-data';
import { ColumnInfo } from './column-info';

export class ExtColumnInfo extends ColumnInfo {

    /**
     * E' una decode property? 
     */
    isDecodeDescriptionProperty: boolean;

    /**
     * Imposta la tipologia di input del campo
     */
    inputMode: ExternalFieldInputMode;

    /**
     * @deprecated utilizzare decodeProperties con la lista dei campi codice che si vogliono visualizzare
     * Gestisce la visibilità del codice se il campo non è una decode description 
     */
    showCodeInDescription?: boolean = null;

    fieldSeparator: string = " - ";

    decodeProperties?: string[] = [];

    searchProperties?: string[] = null;

    isRef?: boolean = false;

    public get isAutocomplete() {
        return this.inputMode === ExternalFieldInputMode.Autocomplete;
    };

    public get isExternalList() {
        return this.inputMode === ExternalFieldInputMode.ExternalList;
    };

    constructor(propertyName: string, path: string = null) {
        super(propertyName, path);
        this.isExternal = true;
    }

}

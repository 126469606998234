import { Expose, Type } from '@nts/std/serialization';
import { GridFieldMetaData } from './grid-field-meta-data';
import { ExternalGridFieldMetaData } from './external-grid-field-meta-data';
import { GridFieldInterface } from './grid-field.interface';

export class GridColumnsMetaData {

    constructor() {
        this.gridFields = [];
        this.externals = [];
    }

    @Expose()
    modelName: string;

    @Expose()
    @Type(() => GridFieldMetaData)
    gridFields: GridFieldMetaData[];

    @Expose()
    @Type(() => ExternalGridFieldMetaData)
    externals: ExternalGridFieldMetaData[];

    @Expose()
    get sortedFields(): GridFieldInterface[] {
        return [...this.gridFields, ...this.externals].sort((a, b) => a.position - b.position);
    }

}

import { Expose } from "@nts/std/serialization";
import { FieldTypes } from './field-types.enum';
import { SimpleFieldMetaData } from './simple-field-meta-data';

export class CustomFieldMetaData extends SimpleFieldMetaData {

  @Expose()
  uniqueId: string;

  override getFieldType(): FieldTypes {
    return FieldTypes.Custom;
  }
}

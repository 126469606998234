import { EventEmitter, Inject, Injectable, InjectionToken, Optional, inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfigurationManager, JwtHelper, OnlineService, TenantType, TokenType } from '@nts/std/utility';
import { LocalstorageHelper } from '@nts/std/utility';
import { BehaviorSubject, firstValueFrom, from, merge, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { GenericServiceResponse } from '../responses/generic-service-response';
import { AuthTokenDto } from '../domain-models/dto/auth-token.dto';
import { UIStarter } from '../starter/ui-starter';
import { LogService } from '@nts/std/utility';
import { UserProfile } from '../domain-models/user/user-profile';
import { TenantProfile } from '../domain-models/license/tenant-profile';
import { classToPlain, ClassConstructor, plainToClass } from '@nts/std/serialization';
import { EnvironmentConfiguration } from '@nts/std/environments';
import { TenantTypes } from '../domain-models/license/tenant-types';
import { EnterpriseDataDto } from './dto/enterprise-data.dto';
import { EnterprisesListDto } from './dto/enterprise-list.dto';
import { Location } from '@angular/common';
import { DecodedTokenInterface } from '@nts/std/utility';
import { TelemetryService } from '@nts/std/telemetry';
import { CookiePreferencesDto } from '../domain-models/user/cookie-preferences-dto';
import { VersionManager } from '../resources/version-manager';
import { FrameworkServiceApiClient } from '../api-clients/framework-service-api-client';
import { HttpHeaders } from '@angular/common/http';
import { CurrentRouteService } from '../routing/current-route.service';
import { BaseError } from '../messages/base-error';
import { PresentationCache } from '../cache/presentation-cache';

let authServiceInstanceCounter = 0;

export const AUTH_MOCK_MODE = new InjectionToken<boolean>('bypass.auth.singleton.check');
@Injectable({
    providedIn: 'root'
})
export class AuthService {

    static ACCESS_TOKEN_KEY = 'accessToken';
    static ACCESS_TOKEN_QUERY_KEY = 'access-token';
    static REFRESH_TOKEN_KEY = 'refreshToken';
    static REFRESH_TOKEN_QUERY_KEY = 'refresh-token';
    static CLEAR_AUTH_QUERY_KEY = 'clear-auth';
    static USER_PROFILE = 'userProfile';
    static TENANT_PROFILE = 'tenantProfile';
    static COMPANY_ID_QUERY_KEY = 'companyId';
    static TENANT_ID_QUERY_KEY = 'tenantId';
    static ENTERPRISE_ID_QUERY_KEY = 'enterpriseId';
    static USER_ID_QUERY_KEY = 'userId';

    static ANALYTICS_COOKIES_QUERY_KEY = 'analyticsCookies';
    static ESSENTIAL_COOKIES_QUERY_KEY = 'essentialCookies';
    static MINUTES_BEFORE_TOKEN_EXPIRE_FOR_RENEW = 5;

    // Alias path da utilizzare per fare riferimento alla rotta di login nel ms login service
    // Tutti i parametri passati in query string a questa rotta verranno inoltrati automaticamente alla vera rotta
    static LOGIN_ALIAS_PATH = 'login-route';
    static LOGIN_CHOOSE_TENANT_ALIAS_PATH = 'choose-tenant';
    static CHANGE_PASSWORD_ALIAS_PATH = 'reset-password';

    static ENTERPRISE_DATA_HEADER_NAME = 'X-ENTERPRISE-DATA';
    static AUTHORIZAZION_HEADER_NAME = 'Authorization';

    static PROFILE_DOMAIN_MODEL_FULL_NAME = 'LoginService.UserObjects.Models.User';

    onSessionExpired = new EventEmitter();
    onRefreshTokenChanged = new EventEmitter();
    onAccessTokenChanged = new EventEmitter<string>();
    onSessionRefreshingNeeded = new EventEmitter<string>();
    onSessionRefreshingError = new EventEmitter<GenericServiceResponse<AuthTokenDto>>();
    onSessionAcquire = new EventEmitter();
    onUserProfileRefreshingNeeded = new BehaviorSubject<boolean>(false);
    onUserProfileRefreshingError = new Subject<GenericServiceResponse<UserProfile>>();
    onTenantProfileRefreshingNeeded = new BehaviorSubject<boolean>(false);
    onTenantProfileRefreshingInProgress = new BehaviorSubject<boolean>(false);
    onTenantProfileRefreshingError = new Subject<GenericServiceResponse<TenantProfile>>();
    onUserProfileUpdate: Subject<UserProfile> = new Subject();
    onEnterpriseDataUpdate: Subject<EnterpriseDataDto> = new Subject();
    onTenantProfileUpdate: Subject<TenantProfile> = new Subject();
    onAccessTokenRetrieved: Subject<string> = new Subject();
    enterpriseDataChanged: BehaviorSubject<boolean> = new BehaviorSubject(false);
    initialized$ = new BehaviorSubject<boolean>(false);

    private refreshTokenInterval = null;
    private currentRouteService: CurrentRouteService;

    private _inIframe = false;
    get inIframe() {
        return this._inIframe;
    }

    constructor(
        private readonly environmentConfiguration: EnvironmentConfiguration,
        private readonly router: Router,
        private readonly location: Location,
        private readonly onlineService: OnlineService,
        private readonly telemetryService: TelemetryService,
        private readonly frameworkServiceApiClient: FrameworkServiceApiClient,
        @Optional()
        @Inject(AUTH_MOCK_MODE) private readonly authMockMode: boolean
    ) {
        authServiceInstanceCounter++;

        if (authServiceInstanceCounter > 1 && authMockMode !== true) {
            throw new Error('AuthService should be a singleton!')
        }

        // Recupera il servizio current route service
        this.currentRouteService = inject(CurrentRouteService);

        this.onAccessTokenRetrieved
            .pipe(distinctUntilChanged())
            .subscribe((accessToken: string) => {
                // Se il token è valido aggiorno il localstoragehelper
                if (accessToken?.length > 0 && this.checkTokenType(accessToken, TokenType.LoginSucceeded)) {
                    if (this.authMockMode) {
                        LocalstorageHelper.setCurrentTenantId(1);
                    } else {
                        const decodedToken = this.decodeToken(accessToken)
                        if (decodedToken) {
                            LocalstorageHelper.setCurrentTenantId(parseInt(decodedToken?.Tnt));
                        }
                    }
                }
        })

        this.onAccessTokenChanged.subscribe(async (newAccessToken: string) => {
            if (newAccessToken?.length > 0 && this.checkTokenType(newAccessToken, TokenType.LoginSucceeded) && !this.authMockMode) {
                await this.checkUserProfileWithAccessToken(newAccessToken);
                await this.checkTenantProfileWithAccessToken(newAccessToken);
            }
        })

        if (authMockMode === true) {
            LocalstorageHelper.init(null, false, null, this.environmentConfiguration, true);
            this.setAccessToken('MOCK_ACCESS_TOKEN');
            this.setRefreshToken('MOCK_REFRESH_TOKEN');
        }

        this._inIframe = window.location.href.includes('iframe=true') && window.self != window.top;
    }

    async checkInitialization(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {

        if (!this.initialized$.value) {

            await this.setAuthDataFromQueryString(window.location.search);
            let accessToken: string = await this.getAccessToken();
            if (accessToken?.length > 0) {
                this.setupTokenRefresh();
            }

            const success = await this.waitUntilAccessTokenIsValidAndAvailable();
            let headers: HttpHeaders = null;

            if (success) {

                accessToken = await this.getAccessToken();

                if (accessToken?.length > 0) {
                    headers = new HttpHeaders().append('Authorization', 'Bearer ' + accessToken);
                }

                if (this.environmentConfiguration.isEnterpriseBarrierRequired) {
                    let enterprisesList = null;
                    if (!this.inIframe) {
                        const enterprisesListResponse: GenericServiceResponse<EnterprisesListDto> = await firstValueFrom(
                            this.frameworkServiceApiClient.getEnterprisesList({
                            bypass: false,
                            enableTimeout: true,
                            force: true
                            }, 0, headers)
                        );
                        if (enterprisesListResponse.operationSuccedeed) {
                            enterprisesList = enterprisesListResponse.result;
                        }
                        // } else if (enterprisesListResponse.operationSuccedeed === false) {
                        //     return false
                        // }
                    } else {
                        const enterprisesListResponse: GenericServiceResponse<EnterprisesListDto> = await firstValueFrom(
                            this.frameworkServiceApiClient.getEnterprisesList({
                            bypass: false,
                            enableTimeout: true,
                            timeoutMillisecond: 1000,
                            }, 0, headers)
                        );
                        if (enterprisesListResponse.operationSuccedeed) {
                            enterprisesList = enterprisesListResponse.result;
                        }
                    }
                    await this.initEnterpriseData(enterprisesList, route, state);
                } else {
                    // provo a settare l'enterprise con i dati presenti in query string se presenti,
                    // così il backend in caso riesce a utilizzarle come informazioni
                    await this.initEnterpriseData(null, route, state);
                }

                if(await this.tokenHasTenantId()){
                    const success = await this.waitUntilTenantIsValidAndAvailable()
                    if (!success) {
                        return false;
                    }
                }
                await this.initCookiePreferences(headers);

            } else
            {
                return false;
            }

            // let cookiePreferences = new CookiePreferencesDto();
            // accessToken = await this.getAccessToken();

            // if (accessToken?.length > 0) {
            //     headers = new HttpHeaders().append('Authorization', 'Bearer ' + accessToken);
            // }
            // try {

            //     await firstValueFrom(
            //     this.frameworkServiceApiClient.getCookiePreferences(null, null, headers).pipe(
            //         map(async (cookiePreferencesResponse) => {
            //         if (cookiePreferencesResponse?.operationSuccedeed) {
            //             cookiePreferences = cookiePreferencesResponse.result;
            //         }
            //         if (this.onlineService.isOnline === true) {
            //             if (cookiePreferences.essentialCookies) {

            //             const tenantProfile = await this.getTenantProfile()

            //             if (!this.telemetryService.initialized) {
            //                 let workerVersions = 'N/A';
            //                 if ('caches' in window) {
            //                     const workerVersionList = (await caches.keys()).filter(key => key.indexOf('assets:app:cache') > -1);
            //                     if (workerVersionList?.length > 0) {
            //                         workerVersions = workerVersionList.join(' - ');
            //                     }
            //                 }

            //                 this.telemetryService.init(
            //                 ConfigurationManager?.Current?.config?.appInsights?.connectionString,
            //                 ConfigurationManager?.Current?.config?.appInsights?.enableAutoRouteTracking,
            //                 {
            //                     ...VersionManager.Current.version,
            //                     workerVersions
            //                 },
            //                 cookiePreferences.analyticsCookies,
            //                 tenantProfile.businessName
            //                 );
            //             } else {
            //                 this.telemetryService.setCanUseAuthenticatedUserContext = cookiePreferences.analyticsCookies;
            //             }

            //             this.telemetryService.setAuthenticatedUserContext(
            //                 await this.getUserProfile(),
            //                 tenantProfile
            //             );

            //             if (!cookiePreferences.analyticsCookies) {
            //                 this.telemetryService.clearAuthenticatedUserContext()
            //             }
            //             } else {
            //             this.telemetryService.clearAuthenticatedUserContext()
            //             }
            //         }
            //         return true;
            //         })
            //     )
            //     )
            // } catch (err) {
            //     LogService.warn(err);
            // }

            this.initialized$.next(true);
        }
        return true;
    }

    private async onAccessTokenBeforeChanged(newAccessToken: string) {
        // Se il token è valido aggiorno il localstoragehelper
        if (newAccessToken?.length > 0 && this.checkTokenType(newAccessToken, TokenType.LoginSucceeded)) {
            if (this.authMockMode) {
                LocalstorageHelper.setCurrentTenantId(1);
            } else {
                const decodedToken = this.decodeToken(newAccessToken)
                if (decodedToken) {
                    LocalstorageHelper.setCurrentTenantId(parseInt(decodedToken?.Tnt));
                }
            }
        }
    }

    private async checkUserProfileWithAccessToken(accessToken: string) {
        const decodedToken = this.decodeToken(accessToken);
        const userProfile: UserProfile = await this.getUserProfile();
        if (userProfile && decodedToken) {
            if (decodedToken.given_name !== userProfile.name) {
                this.onUserProfileRefreshingNeeded.next(true);
            } else if(decodedToken.family_name !== userProfile.lastName) {
                this.onUserProfileRefreshingNeeded.next(true);
            } else if(parseInt(decodedToken.sub) !== userProfile.id) {
                this.onUserProfileRefreshingNeeded.next(true);
            }
        }
    }

    private async checkTenantProfileWithAccessToken(accessToken: string) {
        const decodedToken = this.decodeToken(accessToken);
        const tenantProfile: TenantProfile = await this.getTenantProfile();
        if (tenantProfile && decodedToken) {
            if (parseInt(decodedToken.Tnt) !== tenantProfile.id) {
                this.onTenantProfileRefreshingNeeded.next(true);
            } else if (decodedToken.TntT === TenantType.Provider && tenantProfile.tenantType !== TenantTypes.Provider) {
                this.onTenantProfileRefreshingNeeded.next(true);
            } else if (decodedToken.TntT === TenantType.Partner && tenantProfile.tenantType !== TenantTypes.Partner) {
                this.onTenantProfileRefreshingNeeded.next(true);
            } else if (decodedToken.TntT === TenantType.EndUser && tenantProfile.tenantType !== TenantTypes.EndUser) {
                this.onTenantProfileRefreshingNeeded.next(true);
            }
        }
    }

    /**
     * Imposta prima l'access token e poi il refresh token nello storage
     *
     * @param accessToken       access token da impostare
     * @param refreshToken      refresh token da impostare
     */
    async setAuthData(accessToken: string, refreshToken: string): Promise<void>  {
        if (accessToken != null) {
            await this.setAccessToken(accessToken);
        }
        if (refreshToken != null) {
            await this.setRefreshToken(refreshToken);
        }
    }

    /**
     * Imposta prima l'access token e poi il refresh token nello storage se sono presenti nei query params della query string passata
     *
     * @param queryString query string da analizzare
     */
    async setAuthDataFromQueryString(queryString: string): Promise<void> {
        const urlParams = new URLSearchParams(queryString);
        const accessToken = urlParams.get(AuthService.ACCESS_TOKEN_QUERY_KEY);
        const refreshToken = urlParams.get(AuthService.REFRESH_TOKEN_QUERY_KEY);
        await this.setAuthData(accessToken, refreshToken);
    }

    /**
     * Verifica il query string dello snapshot passato e setta automaticamente nello storage i token se presenti,
     * eventualmente pulisce i token se viene passato il parametro clear-auth.
     * Infine fa il redirect allo stesso url senza i query params sopra elencati.
     *
     * @param route snapshot della rotta da analizzare
     * @param state snapshot dello stato del router da analizzare
     */
    async checkQueryStringFromRoute(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> {
        const accessToken = route.queryParamMap.get(AuthService.ACCESS_TOKEN_QUERY_KEY);
        const refreshToken = route.queryParamMap.get(AuthService.REFRESH_TOKEN_QUERY_KEY);
        const clearAuthToken = route.queryParamMap.get(AuthService.CLEAR_AUTH_QUERY_KEY);

        await this.setAuthData(accessToken, refreshToken);

        if (accessToken != null && refreshToken != null) {

            // Remove access token, refresh token and clearAuthToken from query string
            const queryParams = route.queryParamMap.keys.reduce((obj, item) => {
                if (
                    item !== AuthService.ACCESS_TOKEN_QUERY_KEY &&
                    item !== AuthService.REFRESH_TOKEN_QUERY_KEY &&
                    item !== AuthService.CLEAR_AUTH_QUERY_KEY
                ) {
                    // TODO non stiamo gestendo la queryParamMap.getAll, per gli array di variabili
                    obj[item] = route.queryParamMap.get(item);
                }
                return obj;
            }, {});

            const urlWithoutQueryString = state.url.split('?')[0];

            if (clearAuthToken === 'true') {
                await this.clearAuthData();
            } else {
                this.setupTokenRefresh();
            }

            this.router.navigate([urlWithoutQueryString], {
                queryParams,
            });
        }
    }


    isUserAuthenticated(): Observable<boolean> {

        const isOnline = this.onlineService.isOnline;

        if (this.authMockMode === true || isOnline == false) {
            return of(true);
        }

        return from(this.getRefreshToken()).pipe(switchMap(async (refreshToken: string) => {

            if (!refreshToken) {
                return false;
            }
            const userIsValid = this.isTokenValid(refreshToken);
            if (userIsValid) {
                const userProfile = await this.getUserProfile();

                LogService.updateUserProfile(userProfile);
                this.telemetryService.updateUserProfile(userProfile);

                const userId = await this.getUserId(); // ritorna 0 se non esiste
                // Se il profilo utente non è presente nel local storage o
                // l'id presente nel token è diverso da quello dello user profile
                if (userProfile == null || userProfile?.id !== userId) {
                    this.onUserProfileRefreshingNeeded.next(true);
                }

                if (await this.tokenHasTenantId()) {
                    const tenantProfile = await this.getTenantProfile();

                    LogService.updateTenantProfile(tenantProfile);
                    this.telemetryService.updateTenantProfile(tenantProfile);

                    const tenantId = await this.getTenantId(); // ritorna 0 se non esiste
                    // Se il tenant non è presente nel local storage o
                    // l'id presente nel token è diverso da quello del tenant profile
                    if (tenantProfile == null || tenantProfile?.id !== tenantId) {
                        this.onTenantProfileRefreshingNeeded.next(true);
                    }
                }

                const accessToken = await this.getAccessToken();

                if (!accessToken) {
                    return firstValueFrom(this.waitRenewAccessToken());
                }

                const parsedAccessToken = await this.decodeToken(accessToken);

                if (!parsedAccessToken) {
                    return firstValueFrom(this.waitRenewAccessToken());
                }

                const expMinutesAccessToken = this.getTokenExpireInMinute(parsedAccessToken);

                // Se l'access token è scaduto o è in scadenza lo refresho (default < 5 minuti)
                if (expMinutesAccessToken < AuthService.MINUTES_BEFORE_TOKEN_EXPIRE_FOR_RENEW) {
                    return firstValueFrom(this.waitRenewAccessToken());
                }
            }
            return userIsValid;

        }))
    }

    async getUrlWithTokens(url: string): Promise<string> {
        const concatenateOperator = url.split('?').length > 1 ? '&' : '?';
        const accessToken = await this.getAccessToken();
        const refreshToken = await this.getRefreshToken();
        return `${url}${concatenateOperator}${AuthService.REFRESH_TOKEN_QUERY_KEY}=${refreshToken}&${AuthService.ACCESS_TOKEN_QUERY_KEY}=${accessToken}`;
    }

    /**
     * Recupera l'access tokene presente nello storage.
     * Dopo averlo recuperato emette il Subject onAccessTokenRetrieved
     *
     * @returns l'access token presente nello storage
     */
    async getAccessToken(): Promise<string> {
        const token: string = await LocalstorageHelper.getStorageItem(AuthService.ACCESS_TOKEN_KEY, undefined, false, false) as string;
        if (token) {
            LocalstorageHelper.setCurrentUserId(await this.getCurrentUserId(token));
        }
        this.onAccessTokenRetrieved.next(token);
        return token;
    }

    async changeTenant(tenantId: string | number): Promise<void> {
        // recupero l'url della login
        const baseUrl = await this.getLoginBaseUrl();

        // Delete the TENANT_ID_QUERY_KEY parameter.
        const redirectUrl = new URL(window.location.href);
        let params = new URLSearchParams(redirectUrl.search);
        params.delete(AuthService.TENANT_ID_QUERY_KEY);
        const encodedRedirectUrl = encodeURIComponent(redirectUrl.origin + redirectUrl.pathname + (params.toString()?.length > 0 ? ('?' + params.toString()) : ''));

        // effettuo il redirect
        const newHref = `${baseUrl}/auth/choose-tenant?redirect-url=${encodedRedirectUrl}${tenantId?.toString()?.length > 0 ? `&${AuthService.TENANT_ID_QUERY_KEY}=${tenantId.toString()}` : ''}`;
        UIStarter.startOtherDomainClient(newHref);
    }

    async getLoginBaseUrl(): Promise<string> {
        await PresentationCache.addIfNotExist(AuthService.PROFILE_DOMAIN_MODEL_FULL_NAME);
        const url = PresentationCache.get(AuthService.PROFILE_DOMAIN_MODEL_FULL_NAME)?.replace(new RegExp('user', 'g'), 'profile');
        return url?.split('/manage')[0];
    }

    /**
     * Recupera il refresh tokene presente nello storage.
     *
     * @returns il refresh token presente nello storage
     */
    async getRefreshToken(): Promise<string> {
        const token: string = await LocalstorageHelper.getStorageItem(AuthService.REFRESH_TOKEN_KEY, undefined, false, false) as string;
        return token;
    }

    /**
     * Imposta un nuovo access tokene nello storage.
     * Questo metodo emette is seguenti eventi:
     * - onAccessTokenChanged dopo che viene impostato il nuovo access token
     *
     * @param newAccessToken nuovo access token da impostare
     */
    async setAccessToken(newAccessToken: string): Promise<void> {
        if (newAccessToken != null) {
            await this.onAccessTokenBeforeChanged(newAccessToken);
            await LocalstorageHelper.setStorageItem(AuthService.ACCESS_TOKEN_KEY, newAccessToken, undefined, false, false);
            LocalstorageHelper.setCurrentUserId(await this.getCurrentUserId(newAccessToken));
            this.onAccessTokenChanged.emit(newAccessToken);
        }
    }

    async waitUntilAccessTokenIsValidAndAvailable(): Promise<boolean> {
        let success = true;
        const accessToken = await this.getAccessToken();
        const isTokenValid = accessToken != null && this.isTokenValid(accessToken);
        if (!isTokenValid) {
            await this.notifySessionRefreshingNeeded();
            success = await firstValueFrom(
                merge(
                    this.onSessionRefreshingError.pipe(map(_ => false)),
                    this.onAccessTokenChanged.pipe(map(_ => true)),
                ).pipe(
                    take(1), map((success) => success)
                )
            );
        }
        return success;
    }

    async waitUntilTenantIsValidAndAvailable(): Promise<boolean> {
        let success = true;
        const tenantProfile = await this.getTenantProfile();
        const isTenantValid = tenantProfile != null;
        if (!isTenantValid) {
            this.onTenantProfileRefreshingNeeded.next(true);
            success = await firstValueFrom(
                merge(
                    this.onTenantProfileRefreshingError.pipe(map(_ => false)),
                    this.onTenantProfileUpdate.pipe(map(_ => true)),
                ).pipe(
                    take(1), map((success) => success)
                )
            );
        }
        return success;
    }

    private internalEnterpriseList: EnterprisesListDto = null;

    async setEnterpriseList(enterpriseList: EnterprisesListDto) {
        this.internalEnterpriseList = enterpriseList;
    }

    async getEnterpriseList(): Promise<EnterprisesListDto> {
        return this.internalEnterpriseList;
    }

    /**
     * Recupera il valore di AnalyticsCookie da una stringa
     * @param sourceString
     */
    getAnalyticsCookiesFromString(sourceString: string): boolean {

        let result = false;

        // Recupero il valore di AnalyticsCookies dal query string
        const enterpriseRegexp = /analyticsCookies=([0-9]+)/;
        const enterpriseMatch = sourceString.match(enterpriseRegexp);
        if (enterpriseMatch && enterpriseMatch[1] != null) {
            result = enterpriseMatch[1].toLowerCase() === 'true';
        }
        return result;
    }

    /**
     * Recupera il valore di essentialCookies da una stringa
     * @param sourceString
     */
    getEssentialCookiesFromString(sourceString: string): boolean {

        let result = false;

        // Recupero il valore di EssentialCookies dal query string
        const enterpriseRegexp = /essentialCookies=([0-9]+)/;
        const enterpriseMatch = sourceString.match(enterpriseRegexp);
        if (enterpriseMatch && enterpriseMatch[1] != null) {
            result = enterpriseMatch[1].toLowerCase() === 'true';
        }
        return result;
    }

    async initCookiePreferences(headers: HttpHeaders) {

        let cookiePreferences = new CookiePreferencesDto();

        const hasAnalyticsCookiesInQuery = window.location.href.includes(`${AuthService.ANALYTICS_COOKIES_QUERY_KEY}=`);
        const hasEssentialCookiesInQuery = window.location.href.includes(`${AuthService.ESSENTIAL_COOKIES_QUERY_KEY}=`);

        if (hasAnalyticsCookiesInQuery && hasEssentialCookiesInQuery) {

            cookiePreferences.analyticsCookies = this.getAnalyticsCookiesFromString(window.location.href) ?? false;
            cookiePreferences.essentialCookies = this.getEssentialCookiesFromString(window.location.href) ?? false;
        } else {
            const accessToken = await this.getAccessToken();

            if (accessToken?.length > 0) {
                headers = new HttpHeaders().append('Authorization', 'Bearer ' + accessToken);
            }

            try {

                const cookiePreferencesResponse = await firstValueFrom(
                    this.frameworkServiceApiClient.getCookiePreferences(null, null, headers)
                )

                if (cookiePreferencesResponse?.operationSuccedeed) {
                    cookiePreferences = cookiePreferencesResponse.result;
                }
            } catch (err) {
                LogService.warn(err);
            }
        }

        if (this.onlineService.isOnline === true) {
            if (cookiePreferences.essentialCookies) {

                let tenantProfile = null;
                if(await this.tokenHasTenantId()){
                    tenantProfile = await this.getTenantProfile()
                }

                if (!this.telemetryService.initialized) {
                    let workerVersions = 'N/A';
                    if ('caches' in window) {
                        const workerVersionList = (await caches.keys()).filter(key => key.indexOf('assets:app:cache') > -1);
                        if (workerVersionList?.length > 0) {
                            workerVersions = workerVersionList.join(' - ');
                        }
                    }

                    this.telemetryService.init(
                        ConfigurationManager?.Current?.config?.appInsights?.connectionString,
                        ConfigurationManager?.Current?.config?.appInsights?.enableAutoRouteTracking,
                        {
                            ...VersionManager.Current.version,
                            workerVersions
                        },
                        cookiePreferences.analyticsCookies,
                        tenantProfile ? tenantProfile.businessName: undefined
                    );
                } else {
                    this.telemetryService.setCanUseAuthenticatedUserContext = cookiePreferences.analyticsCookies;
                }

                this.telemetryService.setAuthenticatedUserContext(
                    await this.getUserProfile(),
                    tenantProfile
                );

                if (!cookiePreferences.analyticsCookies) {
                    this.telemetryService.clearAuthenticatedUserContext()
                }
            } else {
                this.telemetryService.clearAuthenticatedUserContext()
            }
        }
    }

    async initEnterpriseData(
        enterprisesList: EnterprisesListDto | null,
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {

        await this.setEnterpriseList(enterprisesList);

        if (enterprisesList?.enterprises?.length >= 0) {

            const hasEnterpriseIdInQuery = window.location.href.includes(`${AuthService.ENTERPRISE_ID_QUERY_KEY}=`);
            const hasCompanyIdInQuery = window.location.href.includes(`${AuthService.COMPANY_ID_QUERY_KEY}=`);
            const currentEnterpriseData = await this.getEnterpriseData(await this.getTenantId());
            const lastUsedEnterpriseData: EnterpriseDataDto = await LocalstorageHelper.getStorageItem('lastUsedEnterpriseData', undefined, undefined, false) as EnterpriseDataDto;

            let enterpriseIdParsedFromQueryString: number = 0;
            let companyIdParsedFromQueryString: number = 0;

            // Se ho dei parametri in query string verifico la coerenza con la lista delle enterprise
            if (hasEnterpriseIdInQuery) {

                // Recupero il codice dell'enterprise dal query string
                enterpriseIdParsedFromQueryString = this.getEnterpiseIdFromString(window.location.href);

                // let foundEnterprise = null;

                // if (enterpriseIdParsedFromQueryString > -1) {

                //     // Verifico enterprise
                //     foundEnterprise = enterprisesList?.enterprises?.find((e) =>
                //         e.enterpriseId === enterpriseIdParsedFromQueryString
                //     );
                // }

                // if (
                //     foundEnterprise ||
                //     this.inIframe // se sono dentro un iframe non posso ricercare nella lista enterprise perchè non viene popolata
                // ) {

                if (hasCompanyIdInQuery) {
                    companyIdParsedFromQueryString = this.getCompanyIdFromString(window.location.href);
                }



                //     let foundCompany = null;
                //     if (companyIdParsedFromQueryString > -1) {

                //         // Verifico company
                //         foundCompany = foundEnterprise?.companies?.find((c) =>
                //             c.companyId === companyIdParsedFromQueryString
                //         );
                //     }

                //     if (foundEnterprise?.companies?.length === 0) {
                //         // Se l'azienda trovata non ha company resetto il company id
                //         // companyIdParsedFromQueryString = -1;
                //     } else if (!foundCompany) {
                //         // companyIdParsedFromQueryString = -1;
                //     }
                // }

            }

            // // se valogono entrambi 0 vuol dire che non sono stati passati quindi devo dedurre in automatico dallo storage l'enterprise selezionata
            // if (enterpriseIdParsedFromQueryString !== -1 || companyIdParsedFromQueryString !== -1) {
            //     const dto = new EnterpriseDataDto();
            //     dto.enterpriseId = enterpriseIdParsedFromQueryString;
            //     dto.companyId = companyIdParsedFromQueryString;

            //     if (currentEnterpriseData?.enterpriseId > 0) {
            //          if (dto.enterpriseId != currentEnterpriseData.enterpriseId || dto.companyId != currentEnterpriseData.companyId) {
            //             // E' cambiato l'enterprise data dal refresh precedente
            //             this.enterpriseDataChanged.next(true);
            //          }
            //     }

            //     await this.setEnterpriseData(dto, await this.getTenantId());
            if (enterpriseIdParsedFromQueryString > 0) {
                // se enterprise id è passato

                const dto = new EnterpriseDataDto();

                if (enterprisesList?.enterprises?.length > 0) {
                    // se ho delle enterprise devo verificare la corettezza dei dati

                    // Verifico enterprise
                    const foundEnterprise = enterprisesList?.enterprises?.find((e) =>
                        e.enterpriseId === enterpriseIdParsedFromQueryString
                    );

                    if (foundEnterprise) {

                        dto.enterpriseId = foundEnterprise.enterpriseId;

                        // Verifico company
                        if (companyIdParsedFromQueryString > 0) {
                            // company passata

                            // Verifico company
                            const foundCompany = foundEnterprise?.companies?.find((e) =>
                                e.companyId === companyIdParsedFromQueryString
                            );

                            if (foundCompany) {
                                dto.companyId = companyIdParsedFromQueryString;
                            } else {
                                // company non trovata
                                dto.companyId = -1;
                            }

                        } else {
                            // company non passata
                            dto.companyId = 0;

                            // se esistono delle company nell'azienda trovata devo restituire errorre di ditta non trovata
                            if (foundEnterprise?.companies?.length > 0) {

                                // company non trovata
                                dto.companyId = -1;
                            }
                        }

                    } else {
                        //enterprise non trovata
                        dto.enterpriseId = -1;
                    }

                } else {
                    // se non ho le enterprise
                    dto.enterpriseId = enterpriseIdParsedFromQueryString;

                    if (companyIdParsedFromQueryString > 0) {
                        dto.companyId = companyIdParsedFromQueryString;
                    }
                }

                await this.setEnterpriseData(dto, await this.getTenantId());
            } else if (currentEnterpriseData?.enterpriseId > 0 && enterprisesList?.enterprises?.find((e) => e.enterpriseId === currentEnterpriseData?.enterpriseId)) {
                // utilizzo il valore salvato nel session storage
                await this.setEnterpriseData(currentEnterpriseData, await this.getTenantId());
            } else if (lastUsedEnterpriseData?.enterpriseId > 0 && enterprisesList?.enterprises?.find((e) => e.enterpriseId === lastUsedEnterpriseData?.enterpriseId)) {
                // utilizzo il valore salvato nel local storage
                await this.setEnterpriseData(lastUsedEnterpriseData, await this.getTenantId());
            } else if (enterprisesList?.enterprises?.length > 0) {
                // utilizzo il valore di defualt di enterprise list, disponibile solo se enterprise list è popolato
                const dto = new EnterpriseDataDto();
                const defaultEnteprise = enterprisesList.enterprises.find(e => e.isDefault === true);
                if (defaultEnteprise) {
                    dto.enterpriseId = defaultEnteprise.enterpriseId;
                    if (defaultEnteprise.companies?.length > 0) {
                        const defaultCompany = defaultEnteprise.companies.find((c) => c.isDefault);
                        if (defaultCompany) {
                            dto.companyId = defaultCompany.companyId;
                        }

                        // } else {
                        //     dto.companyId = defaultEnteprise.companies[0].companyId;
                        // }
                    }

                    await this.setEnterpriseData(dto, await this.getTenantId());
                }
            }

        }
        const newEnterpriseData = await this.getEnterpriseData(await this.getTenantId());

        if (newEnterpriseData?.enterpriseId > 0) {
            const enterpriseId = route.queryParamMap.get(AuthService.ENTERPRISE_ID_QUERY_KEY);
            const companyId = route.queryParamMap.get(AuthService.COMPANY_ID_QUERY_KEY);

            if (parseInt(enterpriseId) != newEnterpriseData?.enterpriseId || (companyId != null && (parseInt(companyId) != newEnterpriseData?.companyId))) {
                // const urlWithoutQueryString = state.url.split('?')[0];
//
                // // Remove access token, refresh token and clearAuthToken from query string
                // let queryParams = route.queryParamMap.keys.reduce((obj, item) => {
                //     if (
                //         item !== AuthService.ENTERPRISE_ID_QUERY_KEY &&
                //         item !== AuthService.COMPANY_ID_QUERY_KEY
                //     ) {
                //         // TODO non stiamo gestendo la queryParamMap.getAll, per gli array di variabili
                //         obj[item] = route.queryParamMap.get(item);
                //     }
                //     return obj;
                // }, {});

                // const tenantId = await this.getTenantId();

                // queryParams = {
                //     ...queryParams,
                //     [AuthService.TENANT_ID_QUERY_KEY]: tenantId.toString(),
                //     [AuthService.ENTERPRISE_ID_QUERY_KEY]: newEnterpriseData?.enterpriseId.toString(),
                // };

                // if ( newEnterpriseData?.companyId > 0) {
                //     queryParams[AuthService.COMPANY_ID_QUERY_KEY]= newEnterpriseData?.companyId.toString()
                // }

                // this.router.navigate([urlWithoutQueryString], {
                //     queryParams
                // });

                const dto = new EnterpriseDataDto();
                dto.enterpriseId = newEnterpriseData?.enterpriseId;
                dto.companyId = newEnterpriseData?.companyId;
                await this.setEnterpriseData(dto, await this.getTenantId());
            }


        } else {
                // Cancello tutte le informazioni presenti sull'enterprise data
            await this.clearEnterpriseData(await this.getTenantId());

            // se esistono delle informazioni dell'enterprise in query string le imposto
            // il backend le sfrutterà parsando poi l'header
            const hasEnterpriseIdInQuery = window.location.href.includes(`${AuthService.ENTERPRISE_ID_QUERY_KEY}=`);
            if (hasEnterpriseIdInQuery) {

                // Recupero il codice dell'enterprise dal query string
                const enterpriseIdParsedFromQueryString = this.getEnterpiseIdFromString(window.location.href);

                const companyIdParsedFromQueryString = this.getCompanyIdFromString(window.location.href);

                if (enterpriseIdParsedFromQueryString > -1) {
                    const dto = new EnterpriseDataDto();
                    dto.enterpriseId = enterpriseIdParsedFromQueryString;
                    if (companyIdParsedFromQueryString > -1) {
                        dto.companyId = companyIdParsedFromQueryString;
                    }
                    await this.setEnterpriseData(dto, await this.getTenantId());
                }
            }
        }

        const urlWithoutQueryString = state.url.split('?')[0];

        // Remove access token, refresh token and clearAuthToken from query string
        let queryParams = route.queryParamMap.keys.reduce((obj, item) => {
            if (
                item !== AuthService.ENTERPRISE_ID_QUERY_KEY &&
                item !== AuthService.COMPANY_ID_QUERY_KEY
            ) {
                // TODO non stiamo gestendo la queryParamMap.getAll, per gli array di variabili
                obj[item] = route.queryParamMap.get(item);
            }
            return obj;
        }, {});

        const tenantId = await this.getTenantId();

        queryParams = {
            ...queryParams,
        };
        if ( tenantId > 0) {
            queryParams[AuthService.TENANT_ID_QUERY_KEY]= tenantId.toString()

            const enterpriseData = await this.getEnterpriseData(tenantId);

            if ( enterpriseData?.enterpriseId > 0) {
                queryParams[AuthService.ENTERPRISE_ID_QUERY_KEY]= enterpriseData?.enterpriseId.toString()
            }

            if ( enterpriseData?.companyId > 0) {
                queryParams[AuthService.COMPANY_ID_QUERY_KEY]= enterpriseData?.companyId.toString()
            }

            const queryStringData = new URLSearchParams(queryParams);
            queryStringData.delete(AuthService.ACCESS_TOKEN_QUERY_KEY);
            queryStringData.delete(AuthService.REFRESH_TOKEN_QUERY_KEY);
            const queryString = queryStringData.toString()
            this.location.go(urlWithoutQueryString +'?'+ queryString);
            // this.router.navigate([urlWithoutQueryString], {
            //     queryParams
            // });
        }
    }

    /**
     * Recupera il codice numerico della company da una stringa
     * @param sourceString
     * @returns ritorna -1 se non trova il codice altrimenti il suo valore
     */
    getCompanyIdFromString(sourceString: string): number {
        let result = -1;

        // Recupero il codice della company dal query string
        const companyRegexp = /companyId=([0-9]+)/;
        const companyMatch =  sourceString.match(companyRegexp);
        if (companyMatch && companyMatch[1] != null) {
            result = parseInt(companyMatch[1]);
        }
        return result;
    }

    /**
     * Recupera il codice numerico della enterprise da una stringa
     * @param sourceString
     * @returns ritorna -1 se non trova il codice altrimenti il suo valore
     */
    getEnterpiseIdFromString(sourceString: string): number {

        let result = -1;

        // Recupero il codice dell'enterprise dal query string
        const enterpriseRegexp = /enterpriseId=([0-9]+)/;
        const enterpriseMatch =  sourceString.match(enterpriseRegexp);
        if (enterpriseMatch && enterpriseMatch[1] != null) {
            result = parseInt(enterpriseMatch[1]);
        }
        return result;
    }

    async getEnterpriseDataError(): Promise<BaseError | null> {
        if (!this.environmentConfiguration.isEnterpriseBarrierRequired) {
            return null;
        }
        const enterpriseList = await this.getEnterpriseList();
        if (!enterpriseList?.enterprises || enterpriseList?.enterprises.length === 0) {
            const error = new BaseError();
            error.description = 'Nessuna azienda associata al tenant corrente';
            error.code = 'EnterpriseListNotFound';
            return error;
        }

        const enterpriseData = await this.getEnterpriseData(await this.getTenantId());
        if (!enterpriseData) {
            const error = new BaseError();
            error.description = 'Azienda non trovata';
            error.code = 'EnterpriseNotFound';
            return error;
        }

        if (
            // enterprise non trovata
            enterpriseData?.enterpriseId === -1 ||

            // enterprise non passata
            enterpriseData?.enterpriseId === 0
        ) {
            const error = new BaseError();
            error.description = 'Azienda non trovata';
            error.code = 'EnterpriseNotFound';
            return error;
        }

        if (
            // companyId non trovato
            enterpriseData?.companyId === -1
        ) {
            const error = new BaseError();
            error.description = 'Ditta non trovata';
            error.code = 'CompanyNotFound';
            return error;
        }

        return null;
    }

    /**
     * Recupera l'enterprise corrente salvata nella session per il tenant corrente
     *
     * @param tenantId
     * @returns
     */
    async getEnterpriseData(tenantId: number): Promise<EnterpriseDataDto> {
        if (tenantId == null || tenantId === 0) {
            return null;
        }
        try {
            return JSON.parse(window.sessionStorage.getItem(`enterpriseData_${tenantId}`));
        } catch (err) {
            return null;
        }
    }

    /**
     * Imposta l'enterprise corrente nella session per il tenant corrent
     *
     * @param enterpriseData        enterprise data da salvare nella session
     * @param tenantId              id del tenant corrente
     */
    async setEnterpriseData(enterpriseData: EnterpriseDataDto, tenantId: number): Promise<void> {
        window.sessionStorage.setItem(`enterpriseData_${tenantId}`, JSON.stringify(enterpriseData));
        LocalstorageHelper.setCurrentEnterpriseId(enterpriseData.enterpriseId);
        await LocalstorageHelper.setStorageItem(`lastUsedEnterpriseData`, enterpriseData, undefined, undefined , false);
        this.onEnterpriseDataUpdate.next(enterpriseData);
    }

    /**
     * Pulisce l'enterprise corrente nella session per il tenant corrent
     *
     * @param enterpriseData        enterprise data da salvare nella session
     * @param tenantId              id del tenant corrente
     */
    async clearEnterpriseData(tenantId: number): Promise<void> {
        window.sessionStorage.removeItem(`enterpriseData_${tenantId}`);
        LocalstorageHelper.setCurrentEnterpriseId(0);
        await LocalstorageHelper.removeStorageItem(`lastUsedEnterpriseData`, undefined, undefined , false);
        this.onEnterpriseDataUpdate.next(null);
    }

    setUserProfile(user: UserProfile): void {
        const plainUser = classToPlain(user, { strategy: 'excludeAll' });
        LocalstorageHelper.setStorageItem(AuthService.USER_PROFILE, plainUser, undefined, false, false);
        LogService.updateUserProfile(user);
        this.telemetryService.updateUserProfile(user);
        this.onUserProfileUpdate.next(user);
    }

    async getUserProfile(): Promise<UserProfile> {
        const plainUser = await LocalstorageHelper.getStorageItem(AuthService.USER_PROFILE, undefined, false, false);
        return plainToClass<UserProfile, object>(UserProfile as ClassConstructor<UserProfile>, plainUser as object, { strategy: 'excludeAll' });
    }

    setTenantProfile(tenant: TenantProfile): void {
        const plainTenant = classToPlain(tenant, { strategy: 'excludeAll' });
        LocalstorageHelper.setStorageItem(AuthService.TENANT_PROFILE, plainTenant, undefined, undefined, false);
        LogService.updateTenantProfile(tenant);
        this.telemetryService.updateTenantProfile(tenant);
        this.onTenantProfileUpdate.next(tenant);
    }

    async getTenantProfile(): Promise<TenantProfile> {
        const plainUser = await LocalstorageHelper.getStorageItem(AuthService.TENANT_PROFILE, undefined, undefined, false);
        return plainToClass<TenantProfile, object>(
            TenantProfile as ClassConstructor<TenantProfile>, plainUser as object, { strategy: 'excludeAll' });
    }

    async setRefreshToken(newRefreshToken: string): Promise<void> {
        if (newRefreshToken != null) {
            await LocalstorageHelper.setStorageItem(AuthService.REFRESH_TOKEN_KEY, newRefreshToken, undefined, false, false);
            this.onRefreshTokenChanged.emit();
        }
    }

    notifySessionExpired(): void {
        this.onSessionExpired.emit();
    }

    async notifySessionRefreshingNeeded(): Promise<void> {
        this.onSessionRefreshingNeeded.emit(await this.getRefreshToken());
    }

    getTokenExpireInMinute(token: any): number {
        const now = new Date();
        const expMinutesToken = (((token.exp * 1000) - now.getTime()) / 60000);
        return expMinutesToken;
    }

    async tokenHasTenantId(): Promise<boolean> {
        const accessToken = await this.getAccessToken();
        if(accessToken){
            const parsedAccessToken = this.decodeToken(accessToken);
            return parsedAccessToken?.Tnt != null;
        }
        return false;
    }

    async getUserName(accessToken: string = null): Promise<string> {
        accessToken = accessToken ?? await this.getAccessToken()
        const parsedAccessToken = this.decodeToken(accessToken);
        return parsedAccessToken?.family_name + ' ' + parsedAccessToken?.given_name;
    }

    async getUserId(accessToken: string = null): Promise<number> {
        accessToken = accessToken ?? await this.getAccessToken()
        const parsedAccessToken = this.decodeToken(accessToken);
        return parseInt(parsedAccessToken?.sub, 10) || 0;
    }

    async getTenantId(accessToken: string = null): Promise<number> {
        accessToken = accessToken ?? await this.getAccessToken()
        const parsedAccessToken = this.decodeToken(accessToken);
        return parseInt(parsedAccessToken?.Tnt, 10) || 0;
    }

    logIn(): void {
        UIStarter.redirectToLogin();
    }

    changePassword(blank = false): void {
        UIStarter.redirectToChangePassword(blank);
    }

    async clearAuthData(): Promise<void> {
        await LocalstorageHelper.removeStorageItem(AuthService.ACCESS_TOKEN_KEY, undefined, false, false);
        await LocalstorageHelper.removeStorageItem(AuthService.REFRESH_TOKEN_KEY, undefined, false, false);
        await LocalstorageHelper.removeStorageItem(AuthService.USER_PROFILE, undefined, false, false);
        await LocalstorageHelper.removeStorageItem(AuthService.TENANT_PROFILE);

        LogService.clearProfiles();
        this.telemetryService.clearProfiles();
    }

    checkTokenHasTenant(token: string): boolean {
        const parsedToken = this.decodeToken(token);
        return parsedToken?.Tnt?.length > 0;
    }

    logOut(): void {
        this.clearAuthData();
        UIStarter.redirectToLogin(true);
    }

    setTokenData(accessData: { access_token: string, refresh_token: string }): Observable<boolean> {
        return from(this.setAccessToken(accessData.access_token)).pipe(switchMap((a) =>
            from(this.setRefreshToken(accessData.refresh_token)).pipe(map(() =>
                true
            ))
        ));
    }

    isTokenValid(token: string): boolean {
        if (token === '' || token == null || !JwtHelper.verifyHeader(token)) {
            return false;
        } else {
            const expTime = this.getExpirationTime(token);
            return expTime ? expTime > new Date() : false;
        }
    }

    checkTokenType(token: string, tokenType: TokenType): boolean {
        if (this.authMockMode) {
            return true;
        }
        const parsedToken: DecodedTokenInterface = this.decodeToken(token);
        return parsedToken ? parsedToken.TkT === tokenType : false;
    }

    checkTokenTenantType(token: string, tenantType: TenantType): boolean {
        const parsedToken = this.decodeToken(token);
        return parsedToken ? parsedToken.TntT === tenantType : false;
    }

    async getCurrentUserId(token?: string): Promise<number> {
        if (this.authMockMode) {
            return 1;
        }
        token = token || await this.getAccessToken();
        const parsedToken = this.decodeToken(token);
        return parsedToken ? parseInt(parsedToken.sub, 10) : 0;
    }

    private async checkAndRenewAccessTokenExpire(): Promise<void> {

        const isOnline = this.onlineService.isOnline;

        if (this.authMockMode === true || isOnline == false) {
            return;
        }

        const accessToken = await this.getAccessToken();
        const refreshToken = await this.getRefreshToken();

        const parsedAccessToken = this.decodeToken(accessToken);
        const parsedRefreshToken = this.decodeToken(refreshToken);

        if (parsedAccessToken != null) {

            const expMinutesAccessToken = this.getTokenExpireInMinute(parsedAccessToken);
            const expMinutesRefreshToken = this.getTokenExpireInMinute(parsedRefreshToken);

            // if 5 min (default) before expire renew access token
            if (expMinutesAccessToken < AuthService.MINUTES_BEFORE_TOKEN_EXPIRE_FOR_RENEW) {
                if (expMinutesRefreshToken > 0) {
                    LogService.info('Access Token is expiring in ' + expMinutesAccessToken.toFixed(0) + ' minutes. It\'s time to renew.');
                    LogService.log('Refresh Token is expiring in ' + expMinutesRefreshToken.toFixed(0) + ' minutes.');
                    this.notifySessionRefreshingNeeded();

                    merge(
                        this.onSessionRefreshingError.pipe(map(_ => false)),
                        this.onAccessTokenChanged.pipe(map(_ => true)),
                    ).pipe(
                        take(1)).subscribe((success) => {
                            if (!success) {
                                this.notifySessionExpired();
                            }
                        });

                } else {
                    this.notifySessionExpired();
                }
            }
        }
    }

    waitRenewAccessToken(): Observable<boolean> {
        this.notifySessionRefreshingNeeded();
        return merge(
            this.onSessionRefreshingError.pipe(map(_ => false)),
            this.onAccessTokenChanged.pipe(map(_ => true)),
        ).pipe(
            take(1), map((success) => success));
    }

    private setupTokenRefresh(): void {
        // this.createRefresTokenFrame();
        // ogni min verifca il token
        if (this.refreshTokenInterval != null) {
            clearInterval(this.refreshTokenInterval);
        }
        // ogni min verifca il token
        this.refreshTokenInterval = setInterval(async () => {

            // Se sono in una rotta autenticata
            if (this.currentRouteService.isAuthenticatedRoute) {
                this.checkAndRenewAccessTokenExpire();
            }
        }, 60000);
    }

    decodeToken(token: string): DecodedTokenInterface | null {
        if (!token || token?.length === 0) {
            return null;
        }
        let decoded: DecodedTokenInterface = null;
        try {
            decoded = JwtHelper.decodeToken(token);
        } catch (e) {
            LogService.warn(e);
        }
        return decoded;
    }

    private getExpirationTime(token: string): Date | boolean {
        const parsedToken = this.decodeToken(token);
        return parsedToken ? new Date((parsedToken.exp * 1000)) : false;
    }
}

import { Inject, Injectable, InjectionToken, Optional } from "@angular/core";
import { ActivatedRoute, GuardsCheckStart, NavigationStart, Router, RouterStateSnapshot } from "@angular/router";
import { RoutingService } from "./routing.service";

export const CURRENT_ROUTE_SERVICE_MOCK_MODE = new InjectionToken<boolean>('bypass.current_route_service.singleton.check');

let currentRouteServiceInstanceCounter = 0;

@Injectable({
    providedIn: 'root'
})
export class CurrentRouteService {

    private internalIsAuthenticatedRoute: boolean;

    get isAuthenticatedRoute() {
        return this.internalIsAuthenticatedRoute;
    }

    setAuthenticatedRouteFromGuard() {
        this.internalIsAuthenticatedRoute = true;
    }

    constructor(
        router: Router,
        @Optional()
        @Inject(CURRENT_ROUTE_SERVICE_MOCK_MODE) private readonly currentRouteServiceMockMode: boolean
    ) {
        currentRouteServiceInstanceCounter++;

        if (currentRouteServiceInstanceCounter > 1  && currentRouteServiceMockMode !== true) {
            throw new Error('CurrentRouteService should be a singleton!')
        }

        router.events.subscribe(evt => {
            if (evt instanceof NavigationStart) {
              this.internalIsAuthenticatedRoute = false;
            }
        });
    }
}

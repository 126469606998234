import { AfterContentInit, Component, ComponentFactoryResolver, ElementRef, EventEmitter, OnInit, ViewChild, ViewContainerRef, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ModalViewModelInterface } from '../../view-models/modal/modal-view-model.interface';
import { ModalCommandWrapper } from '../../view-models/commands/modal-command-wrapper';
import { ModalResult } from '../../view-models/modal/modal-result';
import { UICommandInterface } from '../../view-models/commands/ui-command.interface';
import { ComponentLocator } from '../component-locator';
import { ModalComponentInterface } from './modal-component.interface';
import { MessageResult } from '../../view-models/modal/message-result';
import { UIResultCommand } from '../../view-models/commands/ui-result-command';
import { UIResultCommandInterface } from '../../view-models/commands/ui-result-command.interface';
import { UICommandSettingsManager } from '../../view-models/commands/ui-command-settings-manager';
import { ResultCommandInterface } from '../../view-models/commands/result-command.interface';
import { MessageButton } from '../../view-models/modal/message-button';
import { ViewModelTypeInspector } from '../../decorators/view-model-type.decorator';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MetaDataDescriptions } from '../../meta-data/meta-data-descriptions';
import { MessageResourceManager } from '../../resources/message-resource-manager';
import { MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { BehaviorSubject, take } from 'rxjs';
import { TextButtonComponent } from '../shared/buttons/text-button/text-button.component';
import { FilledButtonComponent } from '../shared/buttons/filled-button/filled-button.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { CdkDrag, CdkDragHandle } from '@angular/cdk/drag-drop';
import { LoaderComponent } from "../shared/loader/loader.component";

@UntilDestroy()
@Component({
    selector: 'nts-modal-container',
    templateUrl: 'modal-container.component.html',
    styleUrls: ['modal-container.component.scss'],
    standalone: true,
    imports: [
        MatDialogModule,
        TextButtonComponent,
        FilledButtonComponent,
        AsyncPipe,
        NgFor,
        NgIf,
        CdkDrag,
        CdkDragHandle,
        LoaderComponent
    ]
})
export class ModalContainerComponent implements OnInit, OnDestroy, AfterContentInit {

    @ViewChild('documentPlaceHolder', { read: ViewContainerRef, static: true })
    documentPlaceHolder: ViewContainerRef;
    dragPosition = {x: 0, y: 0};
    viewModel: ModalViewModelInterface<any, any>;
    title: string;
    active: boolean;
    maximized = false;
    maximizable = false;
    commands = new Array<ModalCommandWrapper<any>>();
    onClose = new BehaviorSubject<ModalResult<any>>(new ModalResult(null, true));
    cancelCommand: UICommandInterface;
    constructor(
        private el: ElementRef,
        private componentFactoryResolver: ComponentFactoryResolver,
        // private _bsModalRef: BsModalRef,
        private componentLocator: ComponentLocator,
        private cd: ChangeDetectorRef,
        public dialogRef: MatDialogRef<any>,
    ) { }

    ngAfterContentInit() {
    }

    ngOnDestroy(): void {
        if (this.viewModel?.onModalClosed) {
            this.viewModel.onModalClosed.next();
        }
        if (this.viewModel?.destroySubscribers$) {
            this.viewModel.destroySubscribers$.next();
        }
    }

    resetPosition() {
       this.dragPosition = {x: 0, y: 0};
    }


    toggleMaximize() {
        this.maximized = !this.maximized;
        this.setMaximizedClass();
        this.resetPosition();
    }

    private setMaximizedClass() {
        if (!this.maximized) {
            this.dialogRef.removePanelClass('fullscreen-dialog');
        } else {
            this.dialogRef.addPanelClass('fullscreen-dialog');
        }
    }

    get modalElement(): HTMLElement {
        return this.el.nativeElement.parentElement.parentElement;
    }

    private async createComponent(
        viewModel: ModalViewModelInterface<any, any>,
        showCancel = true,
        maximizable = false,
        maximized = false) {

        this.viewModel = viewModel;
        const viewModelType = ViewModelTypeInspector.getValue(this.viewModel);
        const componentType = this.componentLocator.getComponentType(viewModelType);
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);
        const componentRef = this.documentPlaceHolder.createComponent(componentFactory);
        const componentInstance = componentRef.instance as ModalComponentInterface;
        componentInstance.viewModel = viewModel;
        if (componentInstance.afterOpened) {
            this.dialogRef.afterOpened().pipe(untilDestroyed(this), take(1)).subscribe(() => componentInstance.afterOpened())
        }

        this.active = true;
        this.maximizable = maximizable;
        this.maximized = maximized;

        this.setMaximizedClass();

        if (showCancel) {
            viewModel.modalCommands.push(this.createButton(MessageResult.Cancel));
        }

        viewModel.modalCommands.forEach(command => {
            const commandWrapper = new ModalCommandWrapper(command, commandResult => {
                if (command.closeModal) {
                    this.closeCore(command, commandResult);
                }
                return commandResult;
            });
            if (command instanceof UIResultCommand) {
                this.commands.push(commandWrapper);
            }
        });

        this.commands.sort((a, b) => {
             return (a.internalCommand.isDefault === b.internalCommand.isDefault) ? 0 : a.internalCommand.isDefault ? 1 : -1;
        });

        if (viewModel.cancelAction) {
            viewModel.cancelAction.pipe(untilDestroyed(this)).subscribe(() => {
                this.closeModal();
            })
        }

        if (componentInstance.initialize) {
            await componentInstance.initialize();
        }

        if(this.viewModel?.changed) {
            this.viewModel.changed.pipe(untilDestroyed(this)).subscribe(() => {
                this.cd.detectChanges();
            })
        }

        return new Promise<ModalResult<any>>((resolve, reject) => {
            this.dialogRef.beforeClosed().pipe(
                untilDestroyed(this)
            ).subscribe(_ => {
                resolve(this.onClose.value);
            });
        });
    }

    private createButton(
        result: MessageResult,
        isDefault = false,
        metaDataDescriptions? : MetaDataDescriptions,
        execute = async (x) => result
    ): UIResultCommandInterface<MessageResult> {

        const cmd = new UIResultCommand(null, execute);

        cmd.displayName = result.toString();

        if (metaDataDescriptions?.displayNameKey) {
            cmd.displayName = MessageResourceManager.Current.getMessage(metaDataDescriptions.displayNameKey);
        }

        if (metaDataDescriptions?.displayName) {
            cmd.displayName = metaDataDescriptions?.displayName;
        }

        cmd.description = cmd.displayName;

        if (metaDataDescriptions?.descriptionKey) {
            cmd.description = MessageResourceManager.Current.getMessage(metaDataDescriptions.descriptionKey);
        }

        if (metaDataDescriptions?.description) {
            cmd.description = metaDataDescriptions?.description;
        }

        cmd.tooltip = cmd.description;
        cmd.name = result.toString();
        cmd.isCancel = result === MessageResult.Cancel;
        cmd.isDefault = isDefault;

        if (!metaDataDescriptions) {
            const manager = new UICommandSettingsManager();
            const stringifiedEnum = MessageResult[result];
            manager.setUICommandByString(stringifiedEnum, cmd);
        }

        return cmd;
    }

    async closeModal() {
        let confirmClose = true;
        if (this.viewModel.closeModal != null) {
            confirmClose = await this.viewModel.closeModal();
        }
        if (confirmClose) {
            this.closeCore(this.createButton(MessageResult.Cancel), null);
        }
    }

    private closeCore<TModalResult>(source: ResultCommandInterface<TModalResult>, result: TModalResult) {
        const modalResult = new ModalResult(result, source.isCancel);
        this.onClose.next(modalResult);
        this.dialogRef.close();
    }

    async showStandardModalAsync(
        modalButtons: MessageButton,
        title: string,
        viewModel: ModalViewModelInterface<any, any>,
        metaDataDescriptionsButtons: MetaDataDescriptions[] = []
    ): Promise<MessageResult> {
        viewModel.modalCommands = this.generateFromMessageButton(modalButtons, metaDataDescriptionsButtons);
        viewModel.modalTitle = title;
        const result = await this.createComponent(viewModel, false);
        return result.result;
    }

    showCustomModalWithResultAsync<TResult>(
      viewModel: ModalViewModelInterface<any, TResult>,
      showCancel = true,
      maximizable = false,
      maximized = false
    ): Promise<ModalResult<TResult>> {
        return this.createComponent(viewModel, showCancel, maximizable, maximized);
    }

    ngOnInit() {

    }

    private generateFromMessageButton(
        modalButtons: MessageButton,
        metaDataDescriptionsButtons: MetaDataDescriptions[] = []
    ): Array<UICommandInterface> {
        const res = new Array<UIResultCommandInterface<MessageResult>>();
        switch (modalButtons) {
            case MessageButton.Ok:
                res.push(this.createButton(MessageResult.Ok, true, metaDataDescriptionsButtons?.length > 0 ? metaDataDescriptionsButtons[0] : null));
                break;
            case MessageButton.OkCancel:
                res.push(this.createButton(MessageResult.Ok, true, metaDataDescriptionsButtons?.length > 0 ? metaDataDescriptionsButtons[0] : null));
                res.push(this.createButton(MessageResult.Cancel, false, metaDataDescriptionsButtons?.length > 1 ? metaDataDescriptionsButtons[1] : null));
                break;
            case MessageButton.YesNo:
                res.push(this.createButton(MessageResult.Yes, true, metaDataDescriptionsButtons?.length > 0 ? metaDataDescriptionsButtons[0] : null));
                res.push(this.createButton(MessageResult.No, false, metaDataDescriptionsButtons?.length > 1 ? metaDataDescriptionsButtons[1] : null));
                break;
            case MessageButton.YesNoCancel:
                res.push(this.createButton(MessageResult.Yes, true, metaDataDescriptionsButtons?.length > 0 ? metaDataDescriptionsButtons[0] : null));
                res.push(this.createButton(MessageResult.No, false, metaDataDescriptionsButtons?.length > 1 ? metaDataDescriptionsButtons[1] : null));
                res.push(this.createButton(MessageResult.Cancel, false, metaDataDescriptionsButtons?.length > 2 ? metaDataDescriptionsButtons[2] : null));
                break;
        }

        return res;
    }
}

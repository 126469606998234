import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, ChangeDetectorRef, SimpleChanges, ChangeDetectionStrategy, HostBinding, SkipSelf } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { StringPropertyViewModel } from '../../../view-models/base-type/string-property-view-model';
import { BaseNumericPropertyViewModel } from '../../../view-models/base-type/base-numeric-property-view-model';
import { BaseEnumPropertyViewModel } from '../../../view-models/base-type/enum-property-view-model';
import { BaseBoolPropertyViewModel } from '../../../view-models/base-type/bool-property-view-model';
import { BaseDateTimePropertyViewModel } from '../../../view-models/base-type/date-time-property-view-model';
import { BaseOffsetPropertyViewModel } from '../../../view-models/base-type/offset-property-view-model';
import { BaseDateTimeOffsetPropertyViewModel, DateTimeOffsetPropertyViewModel } from '../../../view-models/base-type/date-time-offset-property-view-model';
import { GuidPropertyViewModel } from '../../../view-models/base-type/guid-property-view-model';
import { SimpleFieldComponent } from '../core/simple-field/simple-field.component';
import { PropertyViewModelInterface } from '../../../view-models/property-view-model.interface';
import { takeUntil } from 'rxjs/operators';
import { NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { BaseTimeSpanPropertyViewModel, TimeSpanPropertyViewModel } from '../../../view-models/base-type/time-span-property-view-model';
import { PropertyViewModelPropertyChangedEventArgs } from '../../../view-models/property-view-property-changed-event-args';
import { LabelBoxComponent } from '../core/base/label-box/label-box.component';
import { NumericTextBoxComponent } from '../core/numeric-text-box/numeric-text-box.component';
import { EnumOptionsInterface, EnumTextBoxComponent } from '../core/enum-text-box/enum-text-box.component';
import { RadioButtonTextBoxComponent } from '../core/radio-button-text-box/radio-button-text-box.component';
import { ToggleTextBoxComponent } from '../core/toggle-text-box/toggle-text-box.component';
import { CheckboxTextBoxComponent } from '../core/checkbox-text-box/checkbox-text-box.component';
import { DateTextBoxComponent } from '../core/date-text-box/date-text-box.component';
import { StringTextBoxComponent } from '../core/string-text-box/string-text-box.component';
import { TimeSpanTextBoxComponent } from '../core/time-span-text-box/time-span-text-box.component';
import { DateOffsetTextBoxComponent } from '../core/date-offset-text-box/date-offset-text-box.component';
import { AsyncPipe, NgIf } from '@angular/common';
import { ChipsOptionsInterface } from '../core/base/base-chips-box/base-chips-box.component';

@Component({
  selector: 'nts-labelled-text-box',
  templateUrl: './labelled-text-box.component.html',
  styleUrls: ['./labelled-text-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LabelBoxComponent,
    NumericTextBoxComponent,
    EnumTextBoxComponent,
    RadioButtonTextBoxComponent,
    ToggleTextBoxComponent,
    CheckboxTextBoxComponent,
    DateTextBoxComponent,
    StringTextBoxComponent,
    TimeSpanTextBoxComponent,
    DateOffsetTextBoxComponent,
    AsyncPipe,
    NgIf,
  ]
})
export class LabelledTextBoxComponent extends SimpleFieldComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * Property valorizzata solo dal layout custom, vincerà sul pvm.isVisible solo se sarà impostata a false
   */
  @Input() override isHidden = false;

  @HostBinding('class.additional-field')
  @Input() override additionalField = false;

  @HostBinding('class.full-column')
  @Input() fullColumn = false;

  @Input() useToggle = false;

  // #region CHIPS
  @Input() useChips = false;
  @Input() chipsOptions: ChipsOptionsInterface = null;
  // #endregion CHIPS

  @HostBinding('class.multiline')
  @Input() multiline = false;

  @Input() labelPosition = '';
  @Input() propertyViewModel!: PropertyViewModelInterface;
  @Input() tabIndex!: number;
  @Input() appendToBody = true;
  @Input() isDisabled = false;
  @Input() showLabelRequired = false;
  @Input() primaryColor = null;

  @Input() enumOptions: EnumOptionsInterface = null;

  /**
   * lista nome comandi impostati sul propertyViewModel corrente
   */
  @Input() customCommandsName: string[]|null = null;

  @Output() propertyViewModelInitialized = new EventEmitter<void>();

  /**
   * Property calcolata in base alle due property: isHidden e pvm.isVisible
   */
  @HostBinding('class.is-hidden')
  isHiddenCalculated = false;

  ngxPopperjsTriggers = NgxPopperjsTriggers;
  ngxPopperjsPlacements = NgxPopperjsPlacements;

  get name(): string {
    return this.propertyViewModel.propertyName;
  }

  get path(): string {
    return this.propertyViewModel.propertyPath;
  }

  get description(): string {
    return this.propertyViewModel.metadataDescription;
  }

  get displayName(): string {
    return this.propertyViewModel.metadataShortDescription;
  }

  get isString() {
    return this.propertyViewModel instanceof StringPropertyViewModel;
  }

  get isTimeSpan() {
    return this.propertyViewModel instanceof BaseTimeSpanPropertyViewModel;
  }

  get isGuid() {
    return this.propertyViewModel instanceof GuidPropertyViewModel;
  }

  get stringPropertyViewModel() {
    return this.propertyViewModel as StringPropertyViewModel;
  }

  get isDate() {
    return this.propertyViewModel instanceof BaseDateTimePropertyViewModel;
  }

  get datePropertyViewModel() {
    return this.propertyViewModel as BaseDateTimePropertyViewModel;
  }

  get isNumeric() {
    return this.propertyViewModel instanceof BaseNumericPropertyViewModel;
  }

  get numericPropertyViewModel() {
    return this.propertyViewModel as BaseNumericPropertyViewModel;
  }

  get isOffset() {
    return this.propertyViewModel instanceof BaseOffsetPropertyViewModel;
  }

  get isDateTimeOffset() {
    return this.propertyViewModel instanceof BaseDateTimeOffsetPropertyViewModel;
  }

  get dateTimeOffsetPropertyViewModel() {
    return this.propertyViewModel as DateTimeOffsetPropertyViewModel;
  }

  get timeSpanPropertyViewModel() {
    return this.propertyViewModel as TimeSpanPropertyViewModel;
  }

  get isBool() {
    return this.propertyViewModel instanceof BaseBoolPropertyViewModel;
  }

  get isEnum() {
    return this.propertyViewModel instanceof BaseEnumPropertyViewModel && !this.multiline;
  }

  get isRadio(){
    return this.propertyViewModel instanceof BaseEnumPropertyViewModel && this.multiline;
  }

  get enumPropertyViewModel() {
    return this.propertyViewModel as BaseEnumPropertyViewModel;
  }

  get isFullWidth(){
    // deroga per retrocompatibilita multiline, se non viene passata la position
    // la considero sempre full-widht
    if(this.isString && this.multiline && this.labelPosition === '') {
      return true
    }
    return (this.labelPosition === 'top' ? true: false);
  }

  protected destroy$: Subject<boolean> = new Subject<boolean>();
  protected destroyOnChange$: Subject<boolean> = new Subject<boolean>();

  constructor(
    protected readonly cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    if (!this.propertyViewModel) { throw new Error('Missing viewModel!'); }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isHidden']) {
      if (changes['isHidden']?.currentValue === true) {
        this.isHiddenCalculated = true;
      } else {
        this.isHiddenCalculated = !this.propertyViewModel.isVisible;
      }
    } else if (changes['propertyViewModel']) {
      this.destroyOnChange$.next(true);
      this.isHiddenCalculated = this.isHidden === true ? true : !this.propertyViewModel.isVisible;

      // Rimane in ascolto quando cambia il pvm e lo stato degli errori
      merge(
        this.propertyViewModel.customGetterValueChanged,
        this.propertyViewModel.propertyViewModelChanged,
        this.propertyViewModel.onErrorStatusChanged
      ).pipe(
        takeUntil(this.destroy$),
        takeUntil(this.destroyOnChange$)
      ).subscribe((x) => {
        this.cd.detectChanges();
      });

      merge(
        this.propertyViewModel.onFocusRequested,
      ).pipe(
        takeUntil(this.destroy$),
        takeUntil(this.destroyOnChange$)
      ).subscribe((x) => {
        this.additionalField = false;
        this.isHidden = false;
        this.cd.markForCheck();
      });

      // Rimane in ascolto quando cambiano gli attributi del pvm
      this.propertyViewModel.propertyChanged.pipe(
        takeUntil(this.destroy$),
        takeUntil(this.destroyOnChange$)
      ).subscribe((arg: PropertyViewModelPropertyChangedEventArgs) => {
        if (arg.propertyName === 'isVisible') {
          this.isHiddenCalculated = this.isHidden === true ? true : !this.propertyViewModel.isVisible;
        }
        this.cd.detectChanges();
      });

      this.propertyViewModelInitialized.emit();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Now let's also unsubscribe from the subject itself:
    this.destroy$.unsubscribe();
  }
}

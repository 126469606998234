import { AccessMode } from '../meta-data/access-mode.enum';
import { Expose } from "@nts/std/serialization";
import { ColumnInfoVisitInterface } from './column-info-visit.interface';
import { BehaviorSubject } from 'rxjs';

export enum SettingsType { Default, Combo, PropertyType }

export class ColumnInfo implements ColumnInfoVisitInterface {

  path: string;

  isExternal = false;

  @Expose()
  isVisible = true;

  @Expose()
  isEnabled = true;

  @Expose()
  groupIndex = -1;

  @Expose()
  isGhost = false;

  @Expose()
  isAutoSize = true;

  @Expose()
  securityAccess: AccessMode | null = null;

  @Expose()
  width: number;

  @Expose()
  propertyName: string;

  @Expose()
  fieldName: string;
  // TODO: Type?

  @Expose()
  propertyTypeName: string;

  @Expose()
  header: string;

  @Expose()
  headerTooltip: string;

  @Expose()
  position: number;

  @Expose()
  isDateTimeOffset: boolean;

  @Expose()
  settings: SettingsType;

  constructor(propertyName: string, path: string = null) {
    this.propertyName = propertyName;
    this.fieldName = path?.length > 0 ? path + '.' + propertyName + '.value' : propertyName + '.value';
    this.path = path?.length > 0 ? path : null;
    this.settings = SettingsType.PropertyType;
  }


  // TODO rivedere
  visitViewModelPropertySelection(row: any): any {
    // if (row != null) {
    //   return row.GetType().GetProperty(this.propertyName).GetValue(row, null);
    // }
    return null;
  }

  resourceName(): string {
    // return this.propertyType.name;
    return '';
  }


  // event PropertyChangedEventHandler PropertyChanged;

  // protected void OnPropertyChanged(string propName)
  // {
  //     if (PropertyChanged != null)
  //     {
  //         PropertyChanged(this, new PropertyChangedEventArgs(propName));
  //     }
  // }
}

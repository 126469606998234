import { Component, EventEmitter } from "@angular/core";
import { GridUserLayoutDataDto } from "../../../../domain-models/layout/grid-user-layout-data.dto";
import { GridColumnsMetaData } from "../../../../layout-meta-data/grid-columns-meta-data";
import { FieldTypes } from "../../../../layout-meta-data/field-types.enum";
import { BaseFieldComponent } from "../base-field/base-field.component";

@Component({
    template: '',
    selector: 'nts-grid-field'
})
export abstract class GridFieldComponent extends BaseFieldComponent {
    override type = FieldTypes.Grid;
    onColumnsChanged: EventEmitter<GridUserLayoutDataDto> = new EventEmitter();

    abstract get columnDefinition(): GridColumnsMetaData
    abstract get areAllCommandsDisabled(): boolean;

    abstract get showTitle(): boolean;
    abstract get showToolbar(): boolean;
    abstract get dynamicHeight(): boolean;
    abstract get minRowNumberHeight(): number;
}

<ng-template
    *ngIf="propertyViewModel?.securityAccess == null; then baseComponent else securityComponent">
</ng-template>

<ng-template #baseComponent>
    <nts-base-external-box
        #baseExternalBox
        [errorList]="externalPropertyViewModel?.errors$ | async"
        [showDecodeError]="false"
        [minSearchLength]="externalPropertyViewModel.minSearchLength"
        [appendTo]="appendTo"
        [showErrorTooltip]="showErrorTooltip"
        [showErrorBorder]="showErrorBorder"
        [addItemCommand]="addItemCommand"
        [scrollElementClass]="scrollElementClass"
        [isDisabled]="isDisabled || !(externalPropertyViewModel.isEnabled$ | async)"
        [isLoading]="isLoading"
        [isSearchable]="isSearchable"
        [isLocked]="isLocked"
        [isLockedMessage]="isLockedMessage"
        [viewItemCommand]="viewItemCommand"
        [copyCommand]="copyCommand"
        [defaultBorderColor]="externalPropertyViewModel.defaultColor"
        [activeBorderColor]="externalPropertyViewModel.activeColor"
        [hoverBorderColor]="externalPropertyViewModel.hoverColor"
        [searchCommand]="searchCommand"
        [enableOutsideClickListener]="enableOutsideClickListener"
        [basePlainPascalCaseFixedIdentity]="externalPropertyViewModel.parentPlainIdentity$ | async"
        [showCodeInDescription]="showCodeInDescription"
        [getExactItemFromTermCallback]="getExactItemFromTermCallback.bind(this)"
        [getItemFromIdentityCallback]="getItemFromIdentityCallback.bind(this)"
        [getItemsFromTermCallback]="getItemsFromTermCallback.bind(this)"
        [getItemsCallback]="getItemsCallback.bind(this)"
        [value]="updatedExternalValue | async"
        (onKeyDown)="onKeyDown($event)"
        (onBlur)="onBlur($event)"
        (onFinishEditing)="onFinishEditing.emit()"
        (valueChange)="onExternalBoxValueChange.next($event)"
        >
    </nts-base-external-box>
</ng-template>

<ng-template #securityComponent>
    <nts-base-security-text-box
        #baseSecurityTextBox
        [accessMode]="propertyViewModel.securityAccess"
        [value]="propertyViewModel.formattedValue$ | async">
    </nts-base-security-text-box>
</ng-template>

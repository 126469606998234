import { Component } from "@angular/core";
import { FieldTypes } from "../../../../layout-meta-data/field-types.enum";
import { BaseFieldComponent } from "../base-field/base-field.component";
import { SimpleFieldMetaData } from "../../../../layout-meta-data/simple-field-meta-data";

@Component({
    template: '',
    selector: 'nts-external-field'
})
export abstract class ExternalFieldComponent extends BaseFieldComponent {
  override type = FieldTypes.External;
  abstract get availableExternalFields(): SimpleFieldMetaData[];

  abstract get decodeProperties(): string[];
  abstract get searchProperties(): string[];
  abstract get fieldSeparator(): string;
}

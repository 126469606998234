import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, NgZone, OnChanges, OnDestroy, OnInit, Output, Renderer2, SimpleChanges, ViewChild } from "@angular/core";
import { UICommandInterface } from "../../../../../view-models/commands/ui-command.interface";
import { NgxPopperjsDirective, NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from "ngx-popperjs";
import { FormsModule } from "@angular/forms";
import { AsyncPipe, NgClass, NgFor, NgIf } from "@angular/common";
import { RibbonButtonComponent } from "../../../../shared/buttons/ribbon-button/ribbon-button.component";
import { MultiSelect, MultiSelectModule } from 'primeng/multiselect';
import { IMaskModule } from "angular-imask";
import { OverlayOptions } from "primeng/api";
import { PopperHelper } from "@nts/std/utility";


@Component({
    selector: 'nts-base-select-chips-box',
    templateUrl: './base-select-chips-box.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./base-select-chips-box.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        NgxPopperjsModule,
        NgClass,
        NgIf,
        RibbonButtonComponent,
        AsyncPipe,
        NgFor,
        MultiSelectModule,
        IMaskModule
    ]
})
export class BaseSelectChipsBoxComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {

    @Input() tabIndex = -1;
    @Input() isDisabled = false;
    @Input() value = [];
    @Input() isReadonly = false;
    @Input() placeholder = '';
    @Input() customClasses = '';
    @Input() errorList: string[] = [];
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;
    @Input() customCommandList: UICommandInterface[] = [];
    @Input() defaultBorderColor = null;
    @Input() activeBorderColor = null;
    @Input() hoverBorderColor = null;
    @Input() listenClickOutside = false;
    @Input() multiline = false;
    @Input() field = null;
    @Input() options = null;
    @Input() showClear = true;
    @Input() optionLabel = 'description';
    @Input() optionValue = 'code';
    @Input() appendTo: string | HTMLDivElement = null;
    @Input() panelClass = 'dropdown-panel-select-chips-bottom';
    @Input() overlayOptions: OverlayOptions = null

    @Output() onFinishEditing = new EventEmitter();
    @Output() onBlur = new EventEmitter();
    @Output() onFocus = new EventEmitter();
    @Output() onValueChange = new EventEmitter();

    @ViewChild(MultiSelect, { static: true }) selectChipsBox: MultiSelect;
    @ViewChild(NgxPopperjsDirective, { static: true }) popperError: NgxPopperjsDirective;

    documentClickListener: any;
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
    overrideBorderColor = null;
    isActive = false;
    isHover = false;
    mask = null;

    constructor(
        public readonly el: ElementRef,
        private readonly renderer: Renderer2,
        private readonly zone: NgZone,
        private readonly cd: ChangeDetectorRef,
    ) {
    }
    ngAfterViewInit(): void {

        // this.updateChipsValues();
        this.cd.detectChanges();

        setTimeout(() => {
          // set tab index, current component multiSelect has a bug and doesn't set tabindex
          const selectChipsBox = this.selectChipsBox.el.nativeElement;
          selectChipsBox.setAttribute('tabindex', this.tabIndex);
        })

    }

    // private updateChipsValues() {
    //     this.chipsBox.writeValue.call(this.chipsBox, this.value);
    //     this.chipsBox.value = this.value;
    //     this.chipsBox.onModelChange.call(this.chipsBox, (value) => this.valueChange(value));
    //     this.chipsBox.registerOnTouched.call(this.chipsBox, () => {
    //         this.value = this.chipsBox.value;
    //         this.valueChange(this.chipsBox.value);
    //         this.cd.detectChanges();
    //     });

    // }

    onModelChange(value: any): void {
      this.onValueChange.emit(value);
    }

    ngOnInit() {
        if (this.listenClickOutside) {
            this.bindDocumentClickListener();
          }
        this.handleOverridesColors();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['defaultBorderColor'] || changes['activeBorderColor'] || changes['hoverBorderColor']) {
            this.handleOverridesColors();
        }

        if (changes['errorList']) {
            this.checkPopper();
        }

        if (changes['value']) {
            // this.updateChipsValues();
            this.cd.detectChanges();
        }
    }

    focus(e): void {
        this.isActive = true;
        this.handleOverridesColors();
        this.cd.detectChanges();
        this.onFocus.emit(e);
    }

    blur(e) {
        this.popperError?.hide();
        this.cd.detectChanges();
        this.onBlur.emit(e);
    }

    ngOnDestroy() {
        this.unbindDocumentClickListener();
    }

    unbindDocumentClickListener() {
        if (this.documentClickListener) {
            this.documentClickListener();
            this.documentClickListener = null;
        }
    }

    mouseEnter(e) {
        this.isHover = true;
        this.handleOverridesColors();
    }

    mouseLeave(e) {
        this.isHover = false;
        this.handleOverridesColors();
    }

    handleOverridesColors() {
        if (!this.defaultBorderColor || !this.activeBorderColor || !this.hoverBorderColor) {
            // devono essere impostate tutte e tre le variabili
            return;
        }

        this.overrideBorderColor = this.defaultBorderColor;
        if (this.isActive && !this.isDisabled) {
            this.overrideBorderColor = this.activeBorderColor;
        }
        if (this.isHover && !this.isDisabled) {
            this.overrideBorderColor = this.hoverBorderColor;
        }
    }

    bindDocumentClickListener() {
        if (!this.documentClickListener) {
            this.zone.runOutsideAngular(() => {
                const documentTarget: any = this.el ? this.el.nativeElement.ownerDocument : 'document';

                this.documentClickListener = this.renderer.listen(documentTarget, 'click', (event) => {
                    if (this.isOutsideClicked(event)) {
                        this.zone.run(() => {
                            this.onFinishEditing.emit();

                            this.cd.markForCheck();
                        });
                    }

                });
            });
        }
    }

    isOutsideClicked(event: Event) {
        return !(this.el.nativeElement.isSameNode(event.target) || this.el.nativeElement.contains(event.target));
    }

    valueChange(value: any): void {
        this.onValueChange.emit(value);
    }

    private checkPopper() {
        if (this.popperError && (this.errorList == null || this.errorList.length === 0)) {
            PopperHelper.hide(this.popperError);
        }
        // if (this.popperInfo && this.errorList?.length > 0) {
        //     PopperHelper.hide(this.popperInfo);
        // }
    }
}

import moment from 'moment';
import { PropertyViewModel } from '../property-view-model';
import { PropertyViewModelInitializationInfo } from '../property-view-model-initialization-info';
import { TimeSpan } from '../../domain-models/time-span';

export abstract class BaseTimeSpanPropertyViewModel extends PropertyViewModel<TimeSpan>  {

  metadataMaxValue: TimeSpan;
  metadataMinValue: TimeSpan;

  showDays = false;
  showSeconds = true;

  constructor(initInfo: PropertyViewModelInitializationInfo, valueIsNullable: boolean) {
    super(initInfo, valueIsNullable);
  }

  override setValue(value: TimeSpan) {

    // LogService.debug('corrente: ', TimeSpan.formatToString(value));
    // LogService.debug('old: ', TimeSpan.formatToString(this.value));
    if (TimeSpan.formatToString(value) !== TimeSpan.formatToString(this.value)) {
      super.setValue(value);
    }

  }

  setDefaultValueFromLayoutMetaData(value: string|number|boolean) {
    // TODO
  }

  protected getFormattedValue(
    value?: TimeSpan
  ): string {
    return TimeSpan.formatToString(value);
  }
}

export class TimeSpanPropertyViewModel extends BaseTimeSpanPropertyViewModel {

  constructor(initInfo: PropertyViewModelInitializationInfo) {
    super(initInfo, true);
  }

  override setValue(value: TimeSpan | null) {
    super.setValue(value);
  }
}

export class NTimeSpanPropertyViewModel extends BaseTimeSpanPropertyViewModel {

  constructor(initInfo: PropertyViewModelInitializationInfo) {
    super(initInfo, true);
  }

  override setValue(value: TimeSpan | null) {
    super.setValue(value);
  }
}

import { ResultCommandInterface } from './result-command.interface';
import { ResultCommand } from './result-command';
import { UIResultCommandInterface } from './ui-result-command.interface';

export class UIResultCommand<TResult, TParameter = any>
    extends ResultCommand<TResult, TParameter> implements UIResultCommandInterface<TResult, TParameter> {

    override closeModal = true;
    override name: string;

    set displayName(value: string) {
        if (this.displayName$.value != value) {
            this.displayName$.next(value)
        }
    }
    get displayName() {
        return this.displayName$.value;
    }

    set iconClass(value: string) {
        if (this.iconClass$.value != value) {
            this.iconClass$.next(value)
        }
    }
    get iconClass() {
        return this.iconClass$.value;
    }

    set tooltip(value: string) {
        if (this.tooltip$.value != value) {
            this.tooltip$.next(value)
        }
    }
    get tooltip() {
        if (this.tooltip$.value?.length == 0) {
            return this.displayName;
        }
        return this.tooltip$.value;
    }
    smallImage: string;

    /**
     * Indica l'eventuale ToggleButton bindato a questo command è checkato o no.
     * Per i Button "non toggle" questa property vale sempre false, e non è bindata sul datatemplate
     */
    isChecked = false;

    isDropdown = false;
    isTextbox = false;
    description: string;
    shortcutKey: string;
    isDefault: boolean;
    children = [];
}

import { Expose, Type } from '@nts/std/serialization';
import { EnumDataItem } from './enum-data-item';
import { FieldMetaData } from './field-meta-data';
import { FieldTypes } from './field-types.enum';
import { DefaultValueAwareInterface, PrimitiveTypeAwareInterface, SimpleFieldType } from './simple-field-meta-data';

export class GridFieldMetaData extends FieldMetaData implements PrimitiveTypeAwareInterface, DefaultValueAwareInterface {

    @Expose()
    isAutoSize: boolean;

    @Expose()
    width: number;

    @Expose()
    primitiveType?: SimpleFieldType

    @Expose()
    @Type(() => EnumDataItem)
    enumData: EnumDataItem[];

    override getFieldType(): FieldTypes {
      return FieldTypes.Simple;
    }

}

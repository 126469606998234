import { BaseSecurityTextBoxComponent } from '../base/base-security-text-box/base-security-text-box.component';
import { ElementRef, ChangeDetectionStrategy, Component, Input, ViewChild, OnInit, OnChanges, OnDestroy, ChangeDetectorRef, SimpleChanges, AfterViewInit, EventEmitter, Output, NgZone, Renderer2 } from '@angular/core';
import { TextValidator } from '../../../../domain-models/decorators/text.decorator';
import { CharacterCasing, StringPropertyViewModel } from '../../../../view-models/base-type/string-property-view-model';
import { BaseTextAreaBoxComponent } from '../base/base-text-area-box/base-text-area-box.component';
import { BaseTextBoxComponent, BaseTextBoxMaskSettings } from '../base/base-text-box/base-text-box.component';
import { NewBasePropertyTextBox } from '../new-base-property-text-box';
import { LogService, MobileHelper, PopperHelper } from '@nts/std/utility';
import { CurrentMultiLanguageDescriptionPropertyViewModel } from '../../../../view-models/base-type/current-multi-language-description-property-view-model';
import { NgxPopperjsModule, NgxPopperjsPlacements, NgxPopperjsTriggers } from 'ngx-popperjs';
import { Meta } from '@angular/platform-browser';
import { UICommandInterface } from '../../../../view-models/commands/ui-command.interface';
import { AsyncPipe, NgIf } from '@angular/common';
import { BaseChipsBoxComponent, ChipsOptionsInterface, DEFAULT_CHIPS_SEPARATOR } from '../base/base-chips-box/base-chips-box.component';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BaseSelectChipsBoxComponent } from '../base/base-select-chips-box/base-select-chips-box.component';

@UntilDestroy()
@Component({
    selector: 'nts-string-text-box',
    templateUrl: './string-text-box.component.html',
    styleUrls: ['./string-text-box.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        BaseTextBoxComponent,
        NgIf,
        BaseTextAreaBoxComponent,
        NgxPopperjsModule,
        BaseSecurityTextBoxComponent,
        BaseChipsBoxComponent,
        AsyncPipe,
        BaseSelectChipsBoxComponent
    ]
})
export class StringTextBoxComponent extends NewBasePropertyTextBox<string> implements OnInit, OnChanges, OnChanges, AfterViewInit, OnDestroy {


    @Input() override initialValue: string;

    @Input() multiline: boolean = false;
    @Input() softDisable = false; //  TODO Imposta solo il readonly senza disabilitare forse è meglio chiamarla readonly?
    @Input() customCommandsName: string[] = null;
    @Input() selectAllOnFocus = true;
    @Input() showErrorTooltip = true;
    @Input() showErrorBorder = true;
    @Input() listenClickOutside = false
    @Input() useChips = false;
    @Input() chipsOptions: ChipsOptionsInterface = null;
    @Input() appendTo: string | HTMLDivElement = null;

    /**
     * Update model at blur
     */
    @Input() override updateOnBlur = true;

    /**
     * Update model at focus out
     */
    @Input() override updateOnFocusOut = true;

    @Output() onFinishEditing = new EventEmitter();

    @ViewChild('baseTextBox', { static: false }) baseTextBox: BaseTextBoxComponent;
    @ViewChild('baseTextBoxArea', { static: false }) baseTextBoxArea: BaseTextAreaBoxComponent;
    @ViewChild('baseSecurityTextBox', { static: false }) baseSecurityTextBox: BaseSecurityTextBoxComponent;
    @ViewChild('baseChipsBox', { static: false }) baseChipsBox: BaseChipsBoxComponent;
    @ViewChild('baseSelectChipsBox', { static: false }) baseSelectChipsBox: BaseSelectChipsBoxComponent;

    override propertyViewModel: StringPropertyViewModel;
    customCommandList: UICommandInterface[] = [];
    element: ElementRef;
    ngxPopperjsTriggers = NgxPopperjsTriggers;
    ngxPopperjsPlacements = NgxPopperjsPlacements;
    maskSettings: BaseTextBoxMaskSettings;
    mask = null;
    valueForChips: any[]|null = null;
    chipsOptionsList: any[]|null = null

    override ngOnChanges(changes: SimpleChanges) {
        super.ngOnChanges(changes);

        if (changes && changes['propertyViewModel'] && changes['propertyViewModel'].currentValue) {

            const customCommandNameToUse = this.customCommandsName?.length >= 0 ? this.customCommandsName : this.propertyViewModel?.customCommandsName
            this.customCommandList = customCommandNameToUse.map(
                (commandName: string)=> {
                    const uiCommand = this.propertyViewModel.customCommandList.get(commandName)
                    if (!uiCommand) {
                        LogService.warn(
                            `Comando custom pvm "${commandName}" non trovato per la property "${this.propertyViewModel?.propertyPath?.length > 0 ? (this.propertyViewModel?.propertyPath + '.') : ''}${this.propertyViewModel?.propertyName}"`);
                    }
                    return uiCommand;
                }
            ).filter((mustExists: UICommandInterface) => mustExists) || [];
            this.valueForChips = this.getValueForChips();
            this.cd.detectChanges();
        }

        if(changes['chipsOptions']) {
          this.checkChipsOptions();
        }

        if (changes['useChips']) {
            // setTimeout(() =>this.updateEventRegistration());
        }
    }

    checkChipsOptions() {
      // Ad ogni cambio ricalcolo chipsOptionsList
      this.chipsOptionsList = this.getOptionsForChips(this.chipsOptions?.optionsList)
    }

    override propertyValueChanged() {
      this.valueForChips = this.getValueForChips();
    }

    // Visto che siamo in uno string text box posso convertire tutti i valori delle options in strings
    getOptionsForChips<TOption = {code: string, description?: string}>(options: TOption[]) {
      if (!options) {
        return null;
      }
      return options?.map((o) => {
        const newObj = {};
       for (const k of Object.keys(o)) {
        if (o[k] != null) {
          newObj[k] = o[k] + '';
        }
       };
       return newObj;
      })
    }

    constructor(
        private meta: Meta,
        cd: ChangeDetectorRef
    ) {
        super(cd);
    }
    ngOnDestroy(): void {
        MobileHelper.clearFixZoomIssue(this.input, this.meta);
    }

    ngOnInit() {
        if (!this.propertyViewModel) { throw new Error('Missing viewModel!'); }
        this.maskSettings = this.propertyViewModel.maskSettings;
        this.valueForChips = this.getValueForChips();
        this.cd.detectChanges();
    }

    ngAfterViewInit(): void {
        if (this.baseTextBoxArea) {
            this.element = (this.propertyViewModel?.securityAccess == null ? this.baseTextBoxArea.textArea : this.baseSecurityTextBox.securityTextBox);
        } else if (this.useChips && this.chipsOptions?.optionsList == null) {
            this.element = (this.propertyViewModel?.securityAccess == null ? this.baseChipsBox.chipsBox.inputViewChild.nativeElement : this.baseSecurityTextBox.securityTextBox);
        } else if (this.useChips && this.chipsOptions?.optionsList != null) {
            this.element = (this.propertyViewModel?.securityAccess == null ? this.baseSelectChipsBox.selectChipsBox.el.nativeElement : this.baseSecurityTextBox.securityTextBox);
        } else {
            this.element = (this.propertyViewModel?.securityAccess == null ? this.baseTextBox.textBox : this.baseSecurityTextBox.securityTextBox);
        }

        super.init();

        (this.input as HTMLInputElement).onkeypress = (e) => {
            const charCode = (typeof e.which == null) ? e.keyCode : e.which;
            const charStr = this.handleCharacterCasing(String.fromCharCode(charCode));
            if (this.propertyViewModel.metadataAllowedCharacters != null) {
                const allowedCharacters = new TextValidator();
                if (!allowedCharacters.validateAllowedChars(charStr, this.propertyViewModel.metadataAllowedCharacters)) {
                    return false;
                }
            }
            return null;
        };

        MobileHelper.fixZoomIssue(this.input, this.meta);
    }

    untilDestroyedThis(): <U>(source: Observable<U>) => Observable<U> {
        return untilDestroyed<this>(this)
    }

    getValueForChips(): null|any[] {
        const separator = this.chipsOptions?.separator?.length > 0 ? this.chipsOptions.separator : DEFAULT_CHIPS_SEPARATOR;
        let result: any[] = null;
        if (this.initialValue != null) {
            if (this.initialValue.length > 0) {
                result = this.initialValue.split(separator);
            }

        } else {
            if (this.propertyViewModel?.formattedValue?.length > 0) {
                result = this.propertyViewModel.formattedValue.split(separator);
            }
        }
        return result;
    }

    modelChange(val: any) {
        if (this.useChips) {
          if (this.valueForChips != val) {
            this.valueForChips = val;
            if (this.chipsOptions?.optionsList != null) {
              // se ho una lista per le chips allora faccio scattare l'update model
              this.updateModel();
            }
          }
        } else {
            const casingVal = this.handleCharacterCasing(val);
            if (casingVal !== val) {
                this.input.value = casingVal;
            }
        }
    }

    get isMultiLanguage() {
        return this.propertyViewModel instanceof CurrentMultiLanguageDescriptionPropertyViewModel;
    }

    get languageCode() {
        if (this.propertyViewModel instanceof CurrentMultiLanguageDescriptionPropertyViewModel) {
            return this.propertyViewModel?.currentLanguageCode || '';
        } else {
            return '';
        }
    }

    get languageDescription() {
        if (this.propertyViewModel instanceof CurrentMultiLanguageDescriptionPropertyViewModel) {
            return this.propertyViewModel?.currentLanguageDescription || '';
        } else {
            return '';
        }
    }

    get input() {
      if (this.useChips && this.chipsOptions?.optionsList == null) {
        return this.baseChipsBox.chipsBox.inputViewChild.nativeElement;
      } else if (this.useChips && this.chipsOptions?.optionsList != null) {
        return this.baseSelectChipsBox.selectChipsBox.el.nativeElement;
      }
      if (this.element) {
        return this.element.nativeElement;
      } else {
        return null;
      }
    }

    get inputValue() {
        if (this.useChips) {
            const newValue = this.valueForChips?.join(this.chipsOptions?.separator?.length > 0 ? this.chipsOptions.separator : DEFAULT_CHIPS_SEPARATOR);
            return newValue;
        }
        if (this.element && this.maskSettings && this.mask) {
            return this.mask.unmaskedValue;
        } else if (this.element) {
            return this.element.nativeElement.value;
        } else {
            return null;
        }
    }

    selectAllContent($event: FocusEvent) {
        if (this.selectAllOnFocus) {
            setTimeout(() => {
                const tgt = ($event.target as HTMLInputElement);
                if (tgt === document.activeElement) {
                    ($event.target as HTMLInputElement).select();
                }
            }, 50);
        }
    }

    showErroTooltip(): void {
        if (this.baseTextBox?.popperError) {
            PopperHelper.show(this.baseTextBox.popperError);
        } else if (this.baseTextBoxArea?.popperError) {
            PopperHelper.show(this.baseTextBoxArea.popperError);
        }
    }

    // Disabilito il focus out
    // onFocusOut(ev: any) {
    // }

    private handleCharacterCasing(text: string) {
        switch (this.propertyViewModel.metadataCharacterCasing) {
            case CharacterCasing.Lower:
                return text.toLowerCase();
            case CharacterCasing.Upper:
                return text.toUpperCase();
            default:
                return text;
        }
    }
}

import { BaseIdentity } from '../domain-models/base-identity';
import { GetByIdentityRequest } from '../requests/get-by-identity-request';
import { CoreOrchestratorViewModel, MsgClearMode } from './core-orchestrator-view-model';
import { OrchestratorViewModelInterface } from './orchestrator-view-model.interface';
import { RootViewModel } from './root-view-model';
import { ViewModelStates } from './states/view-model-states';
import { ViewModelInterface } from './view-model.interface';
import { ToolBarViewModel } from './tool-bar-view-model';
import { firstValueFrom, merge, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { CreateResponse } from '../responses/create-response';
import { StoreResponse } from '../responses/store-response';
import { StoreRequest } from '../requests/store-request';
import { CommandTypes } from './commands/command-types';
import { MessageContainer } from './message-container';
import { RemoveRequest } from '../requests/remove-request';
import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import moment from 'moment';
import { AutoCompleteModelOptions, CompanySingleAggregateAuditDeactivableModel, CompanySingleAggregateIdentity, CompanySingleAggregateModel, CoreModel, DomainModelState, OCCAuditDeactivableModel, SingleAggregateAuditDeactivableModel, SingleAggregateIdentity, SingleAggregateModel } from '../domain-models';
import { AutoCompleteModelRequest } from '../requests/auto-complete-model-request';
import { ToolBarViewModelInterface } from './tool-bar-view-model.interface';
import { ZoomAdvancedOptions } from '../domain-models/find-options/zoom-advanced-options';
import { AggregateMetaData, DomainModelMetaData, InternalCollectionMetaData, MetaDataUtils } from '../meta-data';
import { Filter, FilterOperators } from '../domain-models/find-options/filter';
import { ZoomUIStarterArgs } from './zoom/zoom-ui-starter-args';
import { ZoomStarterMode } from '../domain-models/zoom/zoom-starter-mode';
import { ZoomOrchestratorViewModelResolver } from './zoom/zoom-orchestrator-view-model-resolver';
import { ZoomResult } from '../domain-models/zoom/zoom-result';
import { CrudApiClient } from '../api-clients/crud/crud-api-client';
import { MessageResourceManager } from '../resources/message-resource-manager';
import { MessageCodes } from '../resources/message-codes';
import { CodeValueMessageArg } from '../resources/code-value-message-arg';
import { MessageButton } from './modal/message-button';
import { MessageResult } from './modal/message-result';
import { SnapShotListViewModel } from './snap-shot-list-view-model';
import { SnapShotAPIClient } from '../api-clients/snap-shot-api-client';
import { ViewModelFactory } from './view-model-factory';
import { GetModelBySnapShotIdentityRequest } from '../requests/get-model-by-snap-shot-identity-request';
import { SnapShotFrameIdentity } from '../domain-models/snap-shot-frame-identity';
import { SnapShotFrame } from '../domain-models/snap-shot-frame';
import { RootModelTypeInspector } from '../api-clients/decorators/root-model-type.decorator';
import { BaseValidator } from '../domain-models/decorators/commons/base-validator';
import { BaseViewModelInterface } from './base-view-model.interface';
import { ExternalViewModel } from './external-view-model';
import { ExternalViewModelInterface } from './external-view-model.interface';
import { InternalViewModel } from './internal-view-model';
import { InternalViewModelInterface } from './internal-view-model.interface';
import { CollectionViewModel } from './collection-view-model';
import { ItemViewModelInterface } from './item-view-model.interface';
import { UIStarter } from '../starter/ui-starter';
import { DateTimeOffset } from '../domain-models/date-time-offset';
import { LogService } from '@nts/std/utility';
import { ClassConstructor, classToPlain, plainToClass } from '@nts/std/serialization';
import { BaseError } from '../messages/base-error';
import { ServiceResponse } from '../responses/service-response';
import { GetByIdentityResponse } from '../responses';
import { NewModifiedViewModelState } from './states/new-modified-view-model-state';
import { UnchangedViewModelState } from './states/unchanged-view-model-state';
import { ModifiedViewModelState } from './states/modified-view-model-state';
import { RoutingService } from '../routing/routing.service';
import { ClassAdditionalInfo } from '@nts/std/utility';
import { PinIdentityToDashboardDto} from '../requests/pin-identity-to-dashboard.dto';
import { PinIdentityToDashboardModalViewModel } from './modal/pin-identity-to-dashboard-modal-view-model';
import { GenericServiceRequest } from '../requests/generic-service-request';

export class OrchestratorViewModel<
    TViewModel extends RootViewModel<TModel, TIdentity>,
    TApiClient extends CrudApiClient<TModel, TIdentity>,
    TModel extends CoreModel<TIdentity>,
    TIdentity extends BaseIdentity>
    extends CoreOrchestratorViewModel<TViewModel, TApiClient, TModel, TIdentity>
    implements OrchestratorViewModelInterface {

    jsonRouteParam: string;
    classAdditionalInfo: ClassAdditionalInfo.Crud;
    mockedRootDomainModel: TModel;
    snapShotListViewModelChanged: ReplaySubject<void> = new ReplaySubject();
    zoomSelectedChanged:Subject<void> = new Subject<void>();
    getByIdentityExecuted:Subject<void> = new Subject<void>();
    storeExecuted:Subject<void> = new Subject<void>();

    get snapShotListViewModel(): SnapShotListViewModel<OCCAuditDeactivableModel<TIdentity>, TIdentity> {
        return this._snapShotListViewModel;
    }

    protected _currentZoomUIStarterArgs: ZoomUIStarterArgs;
    protected onDestroy$: Subject<boolean> = new Subject();

    private _snapShotListViewModel: SnapShotListViewModel<OCCAuditDeactivableModel<TIdentity>, TIdentity>;

    override removeError(item: MessageContainer) {

    }

    override async initialize(): Promise<void> {
        await super.initialize();
        await this.setSnapShotListViewModel();
    }

    override onDestroy() {
        super.onDestroy();
        this.onDestroy$.next(true);
        this.onDestroy$.complete();
    }

    getToolBarMenu(): ToolBarViewModelInterface {
        return new ToolBarViewModel(this);
    }

    isSingleAggregate(): boolean {
        const domainModel = new (this.apiClient.rootModelType)();
        return domainModel instanceof SingleAggregateModel ||
            domainModel instanceof SingleAggregateAuditDeactivableModel;
    }

    isCompanySingleAggregate(): boolean {
        const domainModel = new (this.apiClient.rootModelType)();
        return domainModel instanceof CompanySingleAggregateModel ||
            domainModel instanceof CompanySingleAggregateAuditDeactivableModel;
    }

    // fare override del metodo per modificare il comportamento della maschera e aprire in modalità lettura
    async getJsonIdentityFromIdentityParams(identityToLookFor: string): Promise<string | null> {
        if (this.isSingleAggregate()) {
            const identity = new SingleAggregateIdentity();
            identity.fixedIdentity = 0;
            return JSON.stringify(classToPlain(identity, { strategy: 'excludeAll' }));
        }
        if (this.isCompanySingleAggregate()) {
            const tenantId = await this.authService.getTenantId();
            const enterpriseData = await this.authService.getEnterpriseData(tenantId);
            const identity = new CompanySingleAggregateIdentity();
            identity.companyId = enterpriseData?.companyId;
            return JSON.stringify(classToPlain(identity, { strategy: 'excludeAll' }));
        }
        return identityToLookFor ? RoutingService.decodeUrl(identityToLookFor) : null;
    }

    async getByCurrentIdentity(): Promise<TViewModel> {
        await this.getByIdentity(this.domainModel.currentIdentity);
        return this.rootViewModel;
    }

    async getByJsonIdentity(jsonIdentityInPascalCaseOrInCamelCase: string): Promise<ServiceResponse> {
        // Per aumentare la compatibilità supporto i jsonIdentity in camel case e pascal case
        const parsedIdentity: {[key:string]:any} = JSON.parse(jsonIdentityInPascalCaseOrInCamelCase);
        const camelCaseParsedIdentity = {};
        // Adesso lo converto in camel case
        for (const [key, value] of Object.entries(parsedIdentity)) {
            if (key?.length > 0) {
                camelCaseParsedIdentity[MetaDataUtils.toCamelCase(key)] = value;
            }
        }
        const identity = plainToClass<TIdentity, TIdentity>(
            this.identityType as ClassConstructor<TIdentity>, camelCaseParsedIdentity as TIdentity);
        return await this.getByIdentity(identity);
    }

    async getByIdentity(identity: TIdentity): Promise<ServiceResponse> {
        let response = new ServiceResponse();
        response.operationSuccedeed = false;
        if (await firstValueFrom(this.currentState.canGetByIdentity())) {
            try {
                if (this.actionInProgress == false) {
                    this.eventDispatcher.onActionInProgress.next(true);
                }
                response = await this.getByIdentityImplementationAsync(identity);
                this.getByIdentityExecuted.next();
            } catch (e) {
                LogService.warn('getByIdentity failed', e);
            } finally {
                this.eventDispatcher.onActionInProgress.next(false);
            }
            return response;
        } else {
            const error = new BaseError();
            error.code = 'NotAllowed';
            error.description = MessageResourceManager.Current.getMessageWithStrings(MessageCodes.CommandNotAllowed,
                MessageResourceManager.Current.getMessage('std_CMD_' + CommandTypes[CommandTypes.Create]));
            response.errors = [error];
            this.notAllowedAction(CommandTypes.GetByIdentity);
            return response;
        }
    }

    async getBySnapShotIdentity(identity: SnapShotFrameIdentity): Promise<ViewModelInterface> {
        if (await firstValueFrom(this.currentState.canVersioned())) {
            try {
                if (this.actionInProgress == false) {
                    this.eventDispatcher.onActionInProgress.next(true);
                }
                await this.getBySnapShotIdentityImplementationAsync(identity);
            } catch (e) {
                LogService.error('getBySnapShotIdentity failed', e);
            } finally {
                this.eventDispatcher.onActionInProgress.next(false);
            }
            return this.rootViewModel;
        } else {
            this.notAllowedAction(CommandTypes.GetBySnapShotIdentity);
            return this.rootViewModel;
        }
    }

    async find(): Promise<void> {
        // await this.waitForPendingChangesAsync();

        const result = await this.confirmLosingUnsavedDataAsync();

        if (result) {
            if (this.actionInProgress == false) {
                this.eventDispatcher.onActionInProgress.next(true);
            }
            const zoomOptions: ZoomAdvancedOptions = this.getZoomOptions(this.metadata);
            const rootDomainModelName = this.metadata.rootName;
            const args = this.getZoomUIStarterArgsFromFind(
                this.metadata,
                this.metadata.rootName,
                this.metadata.rootFullName,
                false,
                this.metadata,
                rootDomainModelName,
                zoomOptions
            );
            // args.VMEventDispatcher = EventDispatcher;
            args.zoomStarterMode = ZoomStarterMode.F5;

            this._currentZoomUIStarterArgs = args;

            const lookupControlViewModel = ZoomOrchestratorViewModelResolver.createZoomOrchestratorViewModel();

            await lookupControlViewModel.initialize(args);

            this.eventDispatcher.onActionInProgress.next(false);
            const zoomResult = await this.modalService.showZoomAsync(lookupControlViewModel);

            if (!zoomResult.cancel) {
                if (zoomResult.result.result !== ZoomResult.empty().result) {
                    const identityJSON = this.parseJsonIdentityFromFind(zoomResult.result.result);
                    if (identityJSON !== '') {
                        if (this.actionInProgress == false) {
                            this.eventDispatcher.onActionInProgress.next(true);
                        }
                        try {
                            await this.getByJsonIdentity(identityJSON);
                            this.zoomSelectedChanged.next();
                        } finally {
                            this.eventDispatcher.onActionInProgress.next(false);
                        }
                    }
                }
            }
        }
    }

    async autoComplete(options: AutoCompleteModelOptions): Promise<TModel[]> {
        let autoCompleteResult = null;
        try {
            autoCompleteResult = await this.autoCompleteImplementation(options);
        } catch (error) {
            LogService.warn('autoComplete failed', error);
        } finally {
        }
        return autoCompleteResult;
    }

    async loadBySnapShotIdentityImplementationAsync(identity: SnapShotFrameIdentity): Promise<boolean> {

        const request = new GetModelBySnapShotIdentityRequest<SnapShotFrameIdentity>();
        request.requestData = identity;

        if (this.apiClient instanceof SnapShotAPIClient) {
            return await firstValueFrom(this.apiClient.getModelBySnapShotIdentity(request))
                .then(async (response) => {
                    if (response.operationSuccedeed && response.result != null) {
                        await this.tryRebuildViewModelAsync(response, response.result, false, true);

                        this.softDisableViewModel(this.rootViewModel);

                        this.currentState.getBySnapShotIdentity();
                        const valid = this.viewModelValidate(false);
                        if (!valid) {
                            this.eventDispatcher.onValidationBarCollapsed.next(false);
                        }
                        return true;

                    } else {
                        this.refreshMessagesFromResponse(response, MsgClearMode.ClearOnlyTemporaryMessage);
                        return false;
                    }
                }).catch(err => {
                    LogService.warn('loadBySnapShotIdentityImplementationAsync failed', err);
                    return false;
                });
        }
        return false;
    }

    updateJsonRouteParam(identity = null) {
        let jsonRouteParam: string = null
        if (identity) {
            const plainRouteParam: Record<string, any> = classToPlain(identity, { strategy: 'excludeAll' });
            jsonRouteParam = JSON.stringify(plainRouteParam);
        }
        this.jsonRouteParam = jsonRouteParam;
    }

    async prepareGetByIdentityResponseForViewModel(
        response: GetByIdentityResponse<TModel, TIdentity>,
        identity: TIdentity
    ): Promise<GetByIdentityResponse<TModel, TIdentity>> {
        return response;
    }

    async handleGetByIdentityResponse(response: GetByIdentityResponse<TModel, TIdentity>, identity: TIdentity) {
        response = await this.prepareGetByIdentityResponseForViewModel(response, identity);
        if (response.operationSuccedeed && response.result != null) {
            await this.tryRebuildViewModelAsync(response, response.result, false, true);
            await this.setCurrentStateFromGetByIdentityResponse(response);

            this.updateJsonRouteParam(identity);

            const params = new URLSearchParams(this.queryParams)
            UIStarter.updateCurrentRoute(this.metadata.rootFullName, identity, undefined, '?' + params.toString());
            const valid = this.viewModelValidate(false);
            if (!valid) {
                this.eventDispatcher.onValidationBarCollapsed.next(false);
            }
        } else {
            this.refreshMessagesFromResponse(response, MsgClearMode.ClearOnlyTemporaryMessage);
        }
        return response;
    }

    async loadByIdentityImplementationAsync(identity: TIdentity): Promise<GetByIdentityResponse<TModel, TIdentity>> {
        const request = new GetByIdentityRequest<TIdentity>();
        request.identity = identity;
        return await firstValueFrom(this.apiClient.getByIdentity(request))
            .then(async (response) => this.handleGetByIdentityResponse(response, identity))
            .catch(err => {
                LogService.debug('loadByIdentityImplementationAsync failed', err);
                const response = new GetByIdentityResponse<TModel, TIdentity>(this.apiClient.rootModelType);
                response.operationSuccedeed = false;
                return response;
            });
    }

    async create(force?: boolean): Promise<ServiceResponse> {
        let response = new ServiceResponse();
        response.operationSuccedeed = false;
        const result = force || await this.confirmLosingUnsavedDataAsync();
        if (result) {
            try {
                if (this.actionInProgress == false) {
                    this.eventDispatcher.onActionInProgress.next(true);
                }
                response = await this.createWithClearAsync(true);
                LogService.debug('create crud completed');
            } catch (error) {
                LogService.warn('create crud failed', error);
            } finally {
                this.eventDispatcher.onActionInProgress.next(false);
            }
        }
        return response;
    }

    async createRootEntityAsync(): Promise<CreateResponse<TModel, TIdentity>> {
        if (this.metadata?.rootMetaData?.isMultiLanguage === true) {
            return await firstValueFrom(this.apiClient.createML());
        } else {
            return await firstValueFrom(this.apiClient.create());
        }
    }

    async store(): Promise<boolean> {
        let storeSuccedeed = false;
        if (this.currentState.value === ViewModelStates.Unchanged) {
            return true;
        }

        try {
            if (this.actionInProgress == false) {
                this.eventDispatcher.onActionInProgress.next(true);
            }
            storeSuccedeed = await this.storeImplementation();
            this.storeExecuted.next();
            LogService.debug('store completed');
        } catch (error) {
            LogService.warn('store failed', error);
        } finally {
            this.eventDispatcher.onActionInProgress.next(false);
        }
        return storeSuccedeed;
    }

    async postRemove(): Promise<void> {

    }

    async preRemove(identity: TIdentity): Promise<void> {

    }

    async remove(): Promise<void> {
        if (await this.confirmRemoveAsync()) {
            if (this.actionInProgress == false) {
                this.eventDispatcher.onActionInProgress.next(true);
            }
            try {
                if (await firstValueFrom(this.currentState.canRemove())) {
                    const identity = this.rootViewModel.getDomainModel().currentIdentity;
                    await this.preRemove(identity);
                    const request = new RemoveRequest<TIdentity>();
                    request.identity = identity;
                    const response = await firstValueFrom(this.apiClient.remove(request));
                    if (response.operationSuccedeed) {
                        this.refreshMessagesFromResponse(response, MsgClearMode.ClearAllMessages);
                        await this.postRemove();
                        await this.createWithClearAsync(false);
                    } else {
                        this.refreshMessagesFromResponse(response, MsgClearMode.ClearOnlyTemporaryMessage);
                    }
                } else {
                    this.notAllowedAction(CommandTypes.Remove);
                }
                LogService.debug('remove completed');
            } catch (error) {
                LogService.warn('remove failed', error);
            } finally {
                this.eventDispatcher.onActionInProgress.next(false);
            }
        }
    }

    async restore() {
        const result = await this.confirmLosingUnsavedDataAsync();
        if (result) {
            try {
                if (this.actionInProgress == false) {
                    this.eventDispatcher.onActionInProgress.next(true);
                }
                if (
                    this.currentState.value === ViewModelStates.Modified ||
                    this.currentState.value === ViewModelStates.Versioned
                ) {
                    await this.getByCurrentIdentity();
                    if (this.apiClient instanceof SnapShotAPIClient) {
                        this.snapShotListViewModel.resetSelectedItem();
                    }
                    LogService.debug('restore completed');
                } else if (this.currentState.value === ViewModelStates.NewModified) {
                    await this.create(true);
                    LogService.debug('restore completed');
                }
            } catch (error) {
                LogService.error('restore failed', error);
            } finally {
                this.eventDispatcher.onActionInProgress.next(false);
            }
        }
    }

    async pinIdentityToDashboard(): Promise<boolean> {
        try {
            if (await firstValueFrom(this.canPinIdentityToDashboard())) {

                const pinIdentityToDashboardModalViewModel = new PinIdentityToDashboardModalViewModel();
                pinIdentityToDashboardModalViewModel.title.value = this.title;

                const modalResponse = await this.modalService.showCustomModalWithResultAsync<boolean>(
                    pinIdentityToDashboardModalViewModel,
                    true,
                    false,
                    false,
                    {
                        disableClose: true,
                        panelClass: ['pin-identity-to-dashboard-modal']
                    }
                );

                if (!modalResponse.cancel && modalResponse.result) {
                    const dto = new PinIdentityToDashboardDto<TIdentity>();
                    dto.title = pinIdentityToDashboardModalViewModel.title?.value;
                    if(this.currentState.value == ViewModelStates.Unchanged || this.currentState.value == ViewModelStates.Modified){
                        dto.identity = this.rootViewModel.getDomainModel().currentIdentity;
                    }

                    const request: GenericServiceRequest<PinIdentityToDashboardDto<TIdentity>> = new GenericServiceRequest<PinIdentityToDashboardDto<TIdentity>>();
                        request.requestData = dto;

                    const response = await firstValueFrom(this.apiClient.pinIdentityToDashboard(request));
                    this.toastMessageService.showToastsFromResponse(response);
                    return response.operationSuccedeed;
                }
            } else {
                this.notAllowedAction(CommandTypes.PinToDashboard);
            }
            LogService.debug('pinToDashboard completed');
        } catch (error) {
            LogService.warn('pinToDashboard failed', error);
        } finally {
            this.eventDispatcher.onActionInProgress.next(false);
        }
        return true;

    }

    async toggleLeftSideBar() {

    }

    async toggleRightSideBar() {
        if (this.wingViewModel) {
            this.wingViewModel.isCollapsed = !this.wingViewModel.isCollapsed;
        }
    }

    async toggleSnapShotSideBar() {
        if (this.snapShotListViewModel) {
            this.eventDispatcher.onSnapShotSelected.next(!this.snapShotListViewModel.isSelected);
        }
    }

    // Can Actions
    canCreate(): Observable<boolean> {
        return this.currentStateChanged
            .pipe(
                switchMap(() =>
                    this.currentState
                        .canCreate()
                        .pipe(
                            map(can => can && !(this.metadata?.rootMetaData?.userMetaData?.securityAccess != null))
                        )
                )
            )

    }

    canStore(): Observable<boolean> {
        return this.currentStateChanged
            .pipe(
                switchMap(() =>
                    this.currentState
                        .canStore()
                        .pipe(
                            map(can => can && !(this.metadata?.rootMetaData?.userMetaData?.securityAccess != null))
                        )
                )
            )
    }

    canRemove(): Observable<boolean> {
        return merge(
            this.currentStateChanged,
            this.rootViewModelChanged
        ).pipe(
            switchMap(
                () => this.currentState.canRemove()
                    .pipe(
                        map(canRemove => this.rootViewModel && canRemove && !(this.metadata?.rootMetaData?.userMetaData?.securityAccess != null))
                    )
            )
        )
    }

    canRestore(): Observable<boolean> {
        return this.currentStateChanged
            .pipe(
                switchMap(
                    () => this.currentState.canRestore().pipe(map((canRestore) =>
                        canRestore && !(this.metadata?.rootMetaData?.userMetaData?.securityAccess != null)
                    ))
                )
            )
    }

    canFind(): Observable<boolean> {
        return this.currentStateChanged
            .pipe(
                switchMap(
                    () => this.currentState.canFind().pipe(map((canRestore) =>
                        canRestore && !(this.metadata?.rootMetaData?.userMetaData?.securityAccess != null)
                    ))
                )
            )
    }

    canPinIdentityToDashboard(): Observable<boolean> {
        return of(true);
    }

    canToggleLeftSideBar(): Observable<boolean> {
        return of(false);
    }

    canToggleRightSideBar(): Observable<boolean> {
        return of(this.wingViewModel != null);
    }

    canToggleSnapShotSideBar(): Observable<boolean> {
        return of(this.snapShotListViewModel != null);
    }

    // Is visible
    isVisibleCreate(): Observable<boolean> {
        const isSingleAggregate =
            this.domainModel instanceof SingleAggregateModel ||
            this.domainModel instanceof SingleAggregateAuditDeactivableModel ||
            this.domainModel instanceof CompanySingleAggregateModel ||
            this.domainModel instanceof CompanySingleAggregateAuditDeactivableModel;
        return of(!isSingleAggregate);
    }

    isVisibleStore(): Observable<boolean> {
        return of(true);
    }

    isVisibleRemove(): Observable<boolean> {
        const isSingleAggregate =
            this.domainModel instanceof SingleAggregateModel ||
            this.domainModel instanceof SingleAggregateAuditDeactivableModel ||
            this.domainModel instanceof CompanySingleAggregateModel ||
            this.domainModel instanceof CompanySingleAggregateAuditDeactivableModel;
        return of(!isSingleAggregate);
    }

    isVisibleRestore(): Observable<boolean> {
        return of(true);
    }

    isVisibleFind(): Observable<boolean> {
        const isSingleAggregate =
            this.domainModel instanceof SingleAggregateModel ||
            this.domainModel instanceof SingleAggregateAuditDeactivableModel ||
            this.domainModel instanceof CompanySingleAggregateModel ||
            this.domainModel instanceof CompanySingleAggregateAuditDeactivableModel;
        return of(!isSingleAggregate);
    }

    isVisiblePinIdentityToDashboard(): Observable<boolean> {
        return of(true);
    }

    isVisibleToggleLeftSideBar(): Observable<boolean> {
        return of(false);
    }

    isVisibleToggleRightSideBar(): Observable<boolean> {
        return of(this.wingViewModel != null);
    }

    isVisibleToggleSnapShotSideBar(): Observable<boolean> {
        return of(this.snapShotListViewModel != null);
    }

    // Is highlighted
    isHighlightedToggleLeftSideBar(x: any): Observable<boolean> {
        return of(false);
    }

    isHighlightedToggleRightSideBar(x: any): Observable<boolean> {
        return this.eventDispatcher.onWingCollapsed.pipe(map((isCollapsed) => {
            return !isCollapsed;
        }));
    }

    isHighlightedToggleSnapShotSideBar(x: any): Observable<boolean> {
        return this.eventDispatcher.onSnapShotSelected.pipe(map((isSelected) => {
            return isSelected;
        }));
    }

    protected override getTitle(): string {
        super.getTitle();
        if (!this.rootViewModel) {
            return '';
        }

        const currentTitle = super.getTitle();

        if (this.currentState.value === ViewModelStates.Versioned) {

            const arr = new Array<CodeValueMessageArg>();
            const arg = new CodeValueMessageArg();
            arg.code = MessageCodes.VersionedTitle_TAG_Data;
            arg.value = this.getLastShortDateString();
            arr.push(arg);

            return `${currentTitle} (${MessageResourceManager.Current.getMessageWithArgs(
                MessageCodes.VersionedTitle, arr
            )})`;
        }

        return currentTitle;
    }

    protected async getBySnapShotIdentityImplementationAsync(identity: SnapShotFrameIdentity) {
        const response = await this.loadBySnapShotIdentityImplementationAsync(identity);
        if (!response) {
            LogService.error('getBySnapShotIdentityImplementationAsync failed', response);
            throw new Error('getBySnapShotIdentityImplementationAsync failed');
        }
    }

    protected getByIdentityHasError(response: GetByIdentityResponse<TModel, TIdentity>) {
        return !response.operationSuccedeed;
    }

    protected async getByIdentityImplementationAsync(identity: TIdentity): Promise<GetByIdentityResponse<TModel, TIdentity>> {
        const response = await this.loadByIdentityImplementationAsync(identity);
        if (!this.getByIdentityHasError(response) && response.result == null) {
            // se non è stato trovato nulla allora passo allo stato di New. Se però sono
            // già nello stato di new allora non faccio nulla
            if (this.currentState.value !== ViewModelStates.New) {
                return await this.createWithClearAsync(false);
            }
        }
        return response;
    }

    protected getZoomUIStarterArgsFromFind(
        aggregateMetaData: AggregateMetaData,
        requestedDomainModelName: string,
        requestedDomainModelFullName: string,
        isRemote: boolean,
        callerAggregateMetaData: AggregateMetaData,
        rootDomainModelName: string,
        zoomOptions: ZoomAdvancedOptions
    ): ZoomUIStarterArgs {
        return new ZoomUIStarterArgs(
            aggregateMetaData,
            requestedDomainModelName,
            requestedDomainModelFullName,
            isRemote,
            callerAggregateMetaData,
            rootDomainModelName,
            zoomOptions,
        );
    }

    protected parseJsonIdentityFromFind(jsonString: string): string {
        return jsonString;
    }

    protected getZoomOptions(aggregateMetadata: AggregateMetaData): ZoomAdvancedOptions {

        const zoomOptions: ZoomAdvancedOptions = new ZoomAdvancedOptions();

        // Imposto come filtro la chiave
        const codeFilter: Filter = new Filter();
        codeFilter.name = aggregateMetadata.rootMetaData.identityNames[0];
        const filterType = aggregateMetadata.rootMetaData.getPropertyMetaData(codeFilter.name).getType();
        codeFilter.operator = filterType === 'String' ?
            FilterOperators.StartWith : (filterType === 'Numeric' ? FilterOperators.GreaterOrEqualThan : FilterOperators.Equals);
        codeFilter.value = null;
        zoomOptions.filters.push(codeFilter);

        // Imposto come filtro aggiuntivo la main description
        const mainDescriptionProperty = aggregateMetadata.rootMetaData.getMainDescriptionProperty();
        if (mainDescriptionProperty) {
            const mainDescriptionFilter: Filter = new Filter();
            mainDescriptionFilter.name = mainDescriptionProperty.name;
            const mainDescriptionFilterType = mainDescriptionProperty.getType();

            mainDescriptionFilter.operator = mainDescriptionFilterType === 'String' ?
                FilterOperators.StartWith :
                (mainDescriptionFilterType === 'Numeric' ? FilterOperators.GreaterOrEqualThan : FilterOperators.Equals);

            mainDescriptionFilter.value = null;
            zoomOptions.filters.push(mainDescriptionFilter);
        }

        // aggiungo eventuali default filter definiti nell'override del motodo defaultFindFilters(): Array<Filter>
        zoomOptions.filters.push(...this.defaultFindFilters());
        return zoomOptions;
    }

    protected defaultFindFilters(): Array<Filter> {
        return new Array<Filter>();
    }

    protected async autoCompleteImplementation(options: AutoCompleteModelOptions): Promise<TModel[]> {
        const request = new AutoCompleteModelRequest();
        request.requestData = options;
        return firstValueFrom(this.apiClient.autoCompleteModel(request).pipe(
            tap((res) => {
                if (!res.operationSuccedeed) {
                    this.toastMessageService.showToastsFromResponse(res);
                }
            }),
            map((res) => res.result)
        ));
    }

    protected async setCurrentStateFromGetByIdentityResponse(response: GetByIdentityResponse<TModel, TIdentity>): Promise<void> {
        if (response.result.currentState === DomainModelState.New) {
            this.currentState = new NewModifiedViewModelState(this);
        } else if (response.result.currentState === DomainModelState.Unchanged) {
            this.currentState = new UnchangedViewModelState(this);
        } else if (response.result.currentState === DomainModelState.Modified) {
            this.currentState = new ModifiedViewModelState(this);
        }
    }

    protected async createWithClearAsync(clearPreviousMessages): Promise<CreateResponse<TModel, TIdentity>> {
        const createResponse = await this.createImplementationAsync(clearPreviousMessages);
        const params = new URLSearchParams(this.queryParams)
        // if (createResponse.operationSuccedeed) {
        if (createResponse.operationSuccedeed && (params.get('related') == null || params.get('related')?.toLowerCase() !== 'true')) {
            
            // aggiorno la rotta corrente rimuovento l'eventuale identity
            this.updateJsonRouteParam();

            UIStarter.updateCurrentRoute(this.metadata.rootFullName, undefined, undefined, '?' + params.toString());
            // Disabilito la validazione in fase di creazione
            // this.viewModelValidate(false);
        }
        return createResponse;
    }

    protected async preCreate(): Promise<void> {

    }

    protected async postCreate(): Promise<void> {

    }

    async prepareCreateResponseForViewModel(
        response: CreateResponse<TModel, TIdentity>,
        clearPreviousMessages: boolean
    ): Promise<CreateResponse<TModel, TIdentity>> {
        return response;
    }

    async handleCreateResponse(response: CreateResponse<TModel, TIdentity>, clearPreviousMessages: boolean): Promise<CreateResponse<TModel, TIdentity>> {
        response = await this.prepareCreateResponseForViewModel(response, clearPreviousMessages);
        if (response.operationSuccedeed && response.result != null) {
            await this.tryRebuildViewModelAsync(response, response.result, true, clearPreviousMessages);
            this.currentState.create();
            await this.postCreate();
        } else {
            LogService.debug('createImplementationAsync failed', response);
            // this.toastMessageService.showToastsFromResponse(response);
        }
        return response;
    }

    protected async createImplementationAsync(clearPreviousMessages: boolean): Promise<CreateResponse<TModel, TIdentity>> {

        let ret = new CreateResponse<TModel, TIdentity>(this.apiClient.rootModelType);
        ret.operationSuccedeed = false;
        if (await firstValueFrom(this.currentState.canCreate())) {

            // TODO Tommy: crea rootViewModel virtual
            // Crea un rootViewModel vuoto così da renderizzare l'interfaccia mentre viene fatta la chiamata
            // const entityClass = NTSReflection.getClassMetadata('domainModelEntityType', this.apiClient);
            // const newEntity = this.createMockEntity<TEntity>(entityClass, this.metadata);
            // await this.tryRebuildViewModelAsyncWithoutResponse(newEntity, true, true);
            await this.preCreate();

            const response = await this.createRootEntityAsync();
            ret = await this.handleCreateResponse(response, clearPreviousMessages);

        } else {
            const error = new BaseError();
            error.code = 'NotAllowed';
            error.description = MessageResourceManager.Current.getMessageWithStrings(MessageCodes.CommandNotAllowed,
                MessageResourceManager.Current.getMessage('std_CMD_' + CommandTypes[CommandTypes.Create]));
            ret.errors = [error];
            this.notAllowedAction(CommandTypes.Create);
        }
        return ret;
    }

    protected async storeImplementation(): Promise<boolean> {
        let operationSuccedeed = false;
        if (await firstValueFrom(this.currentState.canStore())) {
            await this.waitForPendingChangesAsync();
            await this.waitForPendingAutocompleteAsync();

            if (this.viewModelValidate(true)) {
                const preStoreResult = await this.preStoreAsync(this.domainModel);
                if (preStoreResult) {
                    const storeRequest = new StoreRequest<TModel>();
                    storeRequest.model = this.domainModel;
                    const response = await firstValueFrom(this.apiClient.store(storeRequest));
                    operationSuccedeed = response.operationSuccedeed;
                    await this.tryRebuildViewModelAsync(response, response.result, false, true);
                    await this.setCurrentStateFromStoreResponse(response);

                    // Aggiorno l'url in query string dopo il salvataggio
                    if (response.operationSuccedeed && this.rootViewModel?.domainModel?.currentIdentity != null) {
                      this.updateJsonRouteParam(this.rootViewModel?.domainModel?.currentIdentity);
                      const params = new URLSearchParams(this.queryParams)
                      UIStarter.updateCurrentRoute(this.metadata.rootFullName, this.rootViewModel?.domainModel?.currentIdentity, undefined, '?' + params.toString());
                    }

                    await this.postStoreAsync(response);
                }
            } else {
                this.eventDispatcher.onValidationBarCollapsed.next(false);
            }
        } else {
            this.notAllowedAction(CommandTypes.Store);
        }
        return operationSuccedeed;
    }

    protected async setCurrentStateFromStoreResponse(response: StoreResponse<TModel, TIdentity>): Promise<void> {
      if (response.operationSuccedeed) {
        this.currentState.store();
      }
  }

    protected async forcingSaveDataAsync(bypassStateCheck = false) {
        let operationSuccedeed = false;
        if (
            this.currentState.value === ViewModelStates.New ||
            this.currentState.value === ViewModelStates.Modified ||
            this.currentState.value === ViewModelStates.NewModified ||
            bypassStateCheck
        ) {
            operationSuccedeed = await this.store();
        } else {
            operationSuccedeed = true;
        }
        return operationSuccedeed;
    }

    protected async preStoreAsync(domainModel: TModel): Promise<boolean> {
        return true;
    }

    protected async postStoreAsync(storeResponse: StoreResponse<TModel, TIdentity>): Promise<void> {
    }

    protected async confirmRemoveAsync(): Promise<boolean> {
        const warningMessage = MessageResourceManager.Current.getMessage(MessageCodes.Warning);
        const args: Array<CodeValueMessageArg> = [];
        const arg = new CodeValueMessageArg();
        arg.code = 'NEWLINE';
        arg.value = '<br>';
        args.push(arg);
        const confirmModalMessage = MessageResourceManager.Current.getMessageWithArgs(MessageCodes.Remove, args);
        const result = await this.modalService.showMessageAsync(warningMessage, confirmModalMessage, MessageButton.YesNo);
        return result === MessageResult.Yes;
    }

    private getLastShortDateString(): string {
        if (this.domainModel instanceof OCCAuditDeactivableModel) {
            if (this.domainModel.updateDate instanceof DateTimeOffset && this.domainModel.updateDate?.dateTime) {
                return moment(this.domainModel.updateDate.dateTime).format('LLL');
            }

            if (this.domainModel.creationDate instanceof DateTimeOffset && this.domainModel.creationDate?.dateTime) {
                return moment(this.domainModel.creationDate.dateTime).format('LLL');
            }
        }
        return '';
    }

    private createMockedAggregateMetaDataForSnapShot() {
        const mockedAggregateMetaData = new AggregateMetaData();
        mockedAggregateMetaData.isDeactivable = true;
        const snapShotListMetaData = new DomainModelMetaData();
        snapShotListMetaData.name = 'SnapShotList';
        const snapShotSearchResultInternalCollectionMetaData = new InternalCollectionMetaData();
        const snapShotSearchResultDomainModelMetaData = new DomainModelMetaData();
        snapShotSearchResultDomainModelMetaData.name = 'SnapShotFrame';
        snapShotSearchResultDomainModelMetaData.identityNames = ['Identity'];

        snapShotSearchResultDomainModelMetaData.numerics = [
            BaseValidator.getPropertyMetaData(SnapShotFrame, 'identity'),
            BaseValidator.getPropertyMetaData(SnapShotFrame, 'creationUserCode'),
            BaseValidator.getPropertyMetaData(SnapShotFrame, 'updateUserCode'),
        ];

        snapShotSearchResultDomainModelMetaData.strings = [
            BaseValidator.getPropertyMetaData(SnapShotFrame, 'creationUser'),
            BaseValidator.getPropertyMetaData(SnapShotFrame, 'updateUser'),
        ];

        snapShotSearchResultDomainModelMetaData.dateTimes = [
            BaseValidator.getPropertyMetaData(SnapShotFrame, 'creationDate'),
            BaseValidator.getPropertyMetaData(SnapShotFrame, 'updateDate'),
        ];

        snapShotSearchResultDomainModelMetaData.propertyNames = [
            ...snapShotSearchResultDomainModelMetaData.numerics.map(m => m.name),
            ...snapShotSearchResultDomainModelMetaData.strings.map(m => m.name),
            ...snapShotSearchResultDomainModelMetaData.dateTimes.map(m => m.name)
        ];

        snapShotSearchResultInternalCollectionMetaData.principalMetaData = snapShotListMetaData;
        snapShotSearchResultInternalCollectionMetaData.dependentMetaData = snapShotSearchResultDomainModelMetaData;
        snapShotSearchResultInternalCollectionMetaData.principalPropertyName = 'SearchResult';

        snapShotListMetaData.internalCollections = [
            snapShotSearchResultInternalCollectionMetaData
        ];
        mockedAggregateMetaData.domainModels = [
            snapShotListMetaData,
            snapShotSearchResultDomainModelMetaData
        ];
        mockedAggregateMetaData.rootMetaData = snapShotListMetaData;
        return mockedAggregateMetaData;
    }

    private async setSnapShotListViewModel(): Promise<void> {
        if (this.apiClient instanceof SnapShotAPIClient) {
            const domainModelClass = RootModelTypeInspector.getValue(this.apiClient);
            const mockedRootDomainModel = this.createMockDomainModel<OCCAuditDeactivableModel<TIdentity>>(domainModelClass, this.metadata);
            const mockedAggregateMetaData = this.createMockedAggregateMetaDataForSnapShot();
            const snapShotListViewModel = await ViewModelFactory.createSnapShotListViewModel<
                SnapShotListViewModel<OCCAuditDeactivableModel<TIdentity>, TIdentity>, OCCAuditDeactivableModel<TIdentity>, TIdentity>(
                    SnapShotListViewModel,
                    mockedRootDomainModel,
                    mockedAggregateMetaData,
                    this,
                    this.apiClient.rootModelType);
            this._snapShotListViewModel = snapShotListViewModel;
            this.snapShotListViewModelChanged.next();

            this.snapShotListViewModel.selectionChanging.pipe(takeUntil(this.onDestroy$)).subscribe(async (model: SnapShotFrame) => {
                if (this.actionInProgress == false) {
                    this.eventDispatcher.onActionInProgress.next(true);
                }
                if (model != null) {
                    await this.getBySnapShotIdentity(model.currentIdentity);
                }
                this.eventDispatcher.onActionInProgress.next(false);
            });
            this.eventDispatcher.onNavigationPanelVisibilityChanged$.next(true);
        }
    }

    private softDisableViewModel(viewModel: ViewModelInterface) {
        viewModel.getProperties().forEach(prop => { prop.isEnabled = false })

        if (viewModel.relationViewModels != null) {
            this.softDisableChildreViewModel(viewModel.relationViewModels);
        }
    }

    private softDisableChildreViewModel(childListViewModel: Map<string, BaseViewModelInterface>) {
        childListViewModel.forEach((childVM) => {
            if (childVM instanceof ExternalViewModel) {
                (childVM as ExternalViewModelInterface).codeProperties.forEach((c) => {
                    c.isEnabled = false;
                })
            } else if (childVM instanceof InternalViewModel) {
                this.softDisableViewModel(childVM as InternalViewModelInterface)
            } else if (childVM instanceof CollectionViewModel) {
                childVM.isEnabled = false;
                childVM.forEach((item: ItemViewModelInterface) => {
                    this.softDisableViewModel(item)
                    item.isEnabled = false;
                })
            }
        })
    }
}

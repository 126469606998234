import { OnChanges, EventEmitter, Input, Output, ChangeDetectorRef, SimpleChanges, Component } from '@angular/core';
import { merge, Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { PropertyViewModelInterface } from '../../../view-models/property-view-model.interface';

@Component({
    template: ''
})
export abstract class BasePropertyComponent<T> implements OnChanges {

    @Input()
    initialValue!: T|null;

    /**
     * Scatta ogni volta che c'è un cambiamento senza fare il blur
     */
    @Output()
    valueChange: EventEmitter<T | null> = new EventEmitter<T | null>();

    @Input()
    propertyViewModel!: PropertyViewModelInterface;

    @Input()
    modelUpdate = true;

    protected destroyOnChange$: Subject<boolean> = new Subject<boolean>();

    abstract get input(): HTMLInputElement | HTMLTextAreaElement;

    protected init() {

    }

    focus(showErroTooltip = false) {
        this.input.focus();
        if (showErroTooltip) {
            this.showErroTooltip();
        }
    }

    abstract showErroTooltip(): void;
    abstract untilDestroyedThis(): <U>(source: Observable<U>) => Observable<U>;

    constructor(protected readonly cd: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges) {

        if (changes && changes['propertyViewModel'] && changes['propertyViewModel'].currentValue) {
            this.destroyOnChange$.next(true);

            const pvm = changes['propertyViewModel'].currentValue as PropertyViewModelInterface;

            // Rimane in ascolto quando cambia il pvm e il valore
            merge(
                pvm.customGetterValueChanged,
                pvm.propertyViewModelChanged,
                pvm.modelChanged,
                pvm.onErrorStatusChanged,
                pvm.propertyChanged.pipe(filter((args) => args.propertyName === this.propertyViewModel.bindedValuePropertyName))
            ).pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$)
            ).subscribe(() => {
                this.propertyValueChanged();
                this.cd.detectChanges();
            });

            // Rimane in ascolto quando cambiano le property diverse dal valore: isVisible, isEnabled, ecc
            pvm.propertyChanged.pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$),
                filter((args) => args.propertyName !== pvm.bindedValuePropertyName)
            ).subscribe(() => {
                this.cd.detectChanges();
            });

            pvm.onFocusRequested.pipe(
                this.untilDestroyedThis(),
                takeUntil(this.destroyOnChange$)
            ).subscribe(() => {
                this.focus(pvm.hasErrors);
                this.input.select();
            });
        }
    }

    propertyValueChanged() {

    }
}

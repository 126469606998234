export enum CommandTypes {
    Restore = 1,
    Create = 2,
    Store = 3,
    Remove = 4,
    Validate = 5,
    GetByIdentity = 6,
    CreateItem = 7,          // Collection
    RemoveItem = 8,          // Collection
    FocusGrid = 9,           // Collection
    ExitFocusGrid = 10,       // Collection
    ExternalReturn = 11,           // F8
    PredefinedZoom = 12,     // F6 Modal
    RestoreZoom = 13,        // F6 Modal
    ExportZoom = 14,         // F6 Modal
    ClearFilters = 15,       // F6 Modal
    Next = 16,               // F6 Modal
    Previous = 17,           // F6 Modal
    Find = 18,               // F6 Modal
    Refresh = 19,            // F6 Modal
    ToggleLeftSidebar = 30,  // Left Sidebar
    ToggleRightSidebar = 31, // Right Sidebar
    ToggleExtendedFilter = 32,
    ToggleSnapShot = 33,        // Snapshot
    ViewItem = 40,           // Autocomplete
    Copy = 41,               // Autocomplete
    ExportCSV = 42,           // Export CSV
    OpenGridSettings = 43,           // Toggle Grid Settings
    ToggleGridFilters = 44,           // Toggle Grid Filter
    SearchGridFilters = 45,           // Search Grid Filter
    HistoryBack = 50,         // Toolbar back button
    GetBySnapShotIdentity = 60,    // Comando
    MoreOptions = 70,
    MobileMenu = 80,
    About = 100,
    CustomizeFields = 101,
    ChangeLayout = 102,
    ResetLayout = 103,
    CustomizeSecurity = 104,
    MoveItemUp = 110,
    MoveItemDown = 111,
    Print = 120,
    ExecuteLongOp = 130,
    Schedule = 140,
    GetByObject = 150,
    ResetColumns = 160,
    StarItem = 170,
    UnstarItem = 171,
    PinToDashboard = 180,
    EditItem = 190,
    SendEmail = 200,
    CallPhone = 210,
}
